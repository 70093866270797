import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  findCouponByIdTraceActions,
  listCouponsTraceActions,
  removeCouponByIdTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_COUPON_REDUCER, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_COUPON_REDUCER>): void => {
    builder.addCase(listCouponsTraceActions.success, (state, { payload }) => {
      state.list = payload
      state.selected = INITIAL_STATE.selected
    })

    builder.addCase(
      findCouponByIdTraceActions.success,
      (state, { payload }) => {
        state.selected = payload
      }
    )

    builder.addCase(
      removeCouponByIdTraceActions.success,
      (state, { payload }) => {
        state.list = state.list.filter(coupon => coupon.id !== payload?.id)
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
