import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  confirmForgotPasswordActions,
  deleteAccountTraceActions,
  getUsersByEmailIdActions,
  initiateForgotPasswordActions,
  registerUserActions,
  resetForgottenPasswordActions
} from '../User/Actions'
import {
  resetUsersByEmailIdAction,
  setForgotPasswordShowOtpAction,
  setForgotPasswordShowOtpLimitPopupAction
} from './Actions'
import { SLICE_NAME } from './Selectors'

interface I_FORGOT_CREDENTIALS_STATE {
  usersByEmailId: Array<{
    id: string
    username: string
  }>
  forgotPasswordRefId: string
  forgotPasswordToken: string
  forgotPasswordShowOtp: boolean
  forgotPasswordShowOtpLimitPopup: boolean
}

const INITIAL_STATE: I_FORGOT_CREDENTIALS_STATE = {
  usersByEmailId: [],
  forgotPasswordRefId: '',
  forgotPasswordToken: '',
  forgotPasswordShowOtp: false,
  forgotPasswordShowOtpLimitPopup: false
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getUsersByEmailIdActions.loading,
      (state: I_FORGOT_CREDENTIALS_STATE) => {
        state.usersByEmailId = []
      }
    )

    builder.addCase(
      getUsersByEmailIdActions.success,
      (state: I_FORGOT_CREDENTIALS_STATE, { payload }) => {
        state.usersByEmailId = payload
      }
    )

    builder.addCase(
      resetUsersByEmailIdAction,
      (state: I_FORGOT_CREDENTIALS_STATE) => {
        state.usersByEmailId = []
      }
    )

    builder.addCase(
      initiateForgotPasswordActions.loading,
      (state: I_FORGOT_CREDENTIALS_STATE) => {
        state.forgotPasswordRefId = ''
      }
    )

    builder.addCase(
      initiateForgotPasswordActions.success,
      (state: I_FORGOT_CREDENTIALS_STATE, { payload }) => {
        const { referenceId } = payload
        state.forgotPasswordRefId = referenceId
      }
    )

    builder.addCase(
      confirmForgotPasswordActions.loading,
      (state: I_FORGOT_CREDENTIALS_STATE) => {
        state.forgotPasswordToken = ''
      }
    )

    builder.addCase(
      confirmForgotPasswordActions.success,
      (state: I_FORGOT_CREDENTIALS_STATE, { payload }) => {
        const { token } = payload
        state.forgotPasswordToken = token
      }
    )

    builder.addCase(
      resetForgottenPasswordActions.success,
      (state: I_FORGOT_CREDENTIALS_STATE) => {
        state.forgotPasswordRefId = ''
        state.forgotPasswordToken = ''
      }
    )

    builder.addCase(
      setForgotPasswordShowOtpAction,
      (state: I_FORGOT_CREDENTIALS_STATE, { payload }) => {
        state.forgotPasswordShowOtp = payload
      }
    )

    builder.addCase(
      setForgotPasswordShowOtpLimitPopupAction,
      (state: I_FORGOT_CREDENTIALS_STATE, { payload }) => {
        state.forgotPasswordShowOtpLimitPopup = payload
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
