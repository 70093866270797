import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

import adultAuthLoader from '../Layouts/Authenticated.Layout/AdultAuthenticated.Layout/adultAuthLoader'
import AdminAdultAuthenticatedLayout from '~/src/Layouts/Authenticated.Layout/AdminAdultAuthenticated.Layout'
import adminAdultAuthLoader from '~/src/Layouts/Authenticated.Layout/AdminAdultAuthenticated.Layout/adminAdultAuthLoader'
import AdminAuthenticatedLayout from '~/src/Layouts/Authenticated.Layout/AdminAuthenticated.Layout'
import adminAuthLoader from '~/src/Layouts/Authenticated.Layout/AdminAuthenticated.Layout/adminAuthLoader'
import AdultAuthenticatedLayout from '~/src/Layouts/Authenticated.Layout/AdultAuthenticated.Layout'
import ChildAuthenticatedLayout from '~/src/Layouts/Authenticated.Layout/ChildAuthenticated.Layout'
import childAuthLoader from '~/src/Layouts/Authenticated.Layout/ChildAuthenticated.Layout/childAuthLoader'
import ErrorBoundary from '~/src/Layouts/ErrorBoundary'
import UnauthenticatedLayout from '~/src/Layouts/Unauthenticated.Layout'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import NotFoundLoader from '~/src/Pages/NotFound/NotFound.Loader'

const LoginPage = React.lazy(() => import('~/src/Pages/Login/Login.Page'))

const RegisterPage = React.lazy(
  () => import('~/src/Pages/Register/Register.Page')
)
const ForgotUsernameIdentifierEmailPage = React.lazy(
  () =>
    import(
      '~/src/Pages/ForgotUsernameIdentifierEmail/ForgotUsernameIdentifierEmail.Page'
    )
)
const ForgotUsernameListPage = React.lazy(
  () => import('~/src/Pages/ForgotUsernameList/ForgotUsernameList.Page')
)
const ForgotPasswordIdentifierUsernamePage = React.lazy(
  () =>
    import(
      '~/src/Pages/ForgotPasswordIdentifierUsername/ForgotPasswordIdentifierUsername.Page'
    )
)
const ForgotPasswordSetPage = React.lazy(
  () => import('~/src/Pages/ForgotPasswordSet/ForgotPasswordSet.Page')
)

const DashboardAdminPage = React.lazy(
  () => import('~/src/Pages/DashboardAdmin/DashboardAdmin.Page')
)

const DashboardAdultPage = React.lazy(
  () => import('~/src/Pages/DashboardAdult/DashboardAdult.Page')
)

const DashboardChildPage = React.lazy(
  () => import('~/src/Pages/DashboardChild/DashboardChild.Page')
)

const BookListPage = React.lazy(
  () => import('~/src/Pages/BookList/BookList.Page')
)

const BookFormBookInfoPage = React.lazy(
  () => import('~/src/Pages/BookFormBookInfo/BookFormBookInfo.Page')
)

const BookFormQuestionsPage = React.lazy(
  () => import('~/src/Pages/BookFormQuestions/BookFormQuestions.Page')
)

const BookFormReviewPage = React.lazy(
  () => import('~/src/Pages/BookFormReview/BookFormReview.Page')
)

const BookShowPage = React.lazy(
  () => import('~/src/Pages/BookShow/BookShow.Page')
)

const CompetitionListPage = React.lazy(
  () => import('~/src/Pages/CompetitionList/CompetitionList.Page')
)

const CompetitionListChildPage = React.lazy(
  () => import('~/src/Pages/CompetitionListChild/CompetitionListChild.Page')
)

const CompetitionListCompletedChildPage = React.lazy(
  () =>
    import(
      '~/src/Pages/CompetitionListCompletedChild/CompetitionListCompletedChild.Page'
    )
)

const CompetitionNewPage = React.lazy(
  () => import('~/src/Pages/CompetitionNew/CompetitionNew.Page')
)

const CompetitionShowPage = React.lazy(
  () => import('~/src/Pages/CompetitionShow/CompetitionShow.Page')
)

const CompetitionShowChildPage = React.lazy(
  () => import('~/src/Pages/CompetitionShowChild/CompetitionShowChild.Page')
)

const CompetitionReviewPage = React.lazy(
  () => import('~/src/Pages/CompetitionReview/CompetitionReview.Page')
)

const EventListPage = React.lazy(
  () => import('~/src/Pages/EventList/EventList.Page')
)

const EventNewPage = React.lazy(
  () => import('~/src/Pages/EventNew/EventNew.Page')
)

const EventReviewPage = React.lazy(
  () => import('~/src/Pages/EventReview/EventReview.Page')
)

const EventListChildPage = React.lazy(
  () => import('~/src/Pages/EventListChild/EventListChild.Page')
)

const EventListCompletedChildPage = React.lazy(
  () =>
    import('~/src/Pages/EventListCompletedChild/EventListCompletedChild.Page')
)

const GroupListPage = React.lazy(
  () => import('~/src/Pages/GroupList/GroupList.Page')
)

const ProfilePage = React.lazy(() => import('~/src/Pages/Profile/Profile.Page'))
const ProfileChildPage = React.lazy(
  () => import('~/src/Pages/ProfileChild/ProfileChild.Page')
)

const GroupNewPage = React.lazy(
  () => import('~/src/Pages/GroupNew/GroupNew.Page')
)

const GroupShowPage = React.lazy(
  () => import('~/src/Pages/GroupShow/GroupShow.Page')
)

const UserListPage = React.lazy(
  () => import('~/src/Pages/UserList/UserList.Page')
)
const UserProfilePage = React.lazy(
  () => import('~/src/Pages/UserProfile/UserProfile.Page')
)

const BookReadPage = React.lazy(
  () => import('~/src/Pages/BookRead/BookRead.Page')
)

const BookUploadGratificationPage = React.lazy(
  () =>
    import('~/src/Pages/BookUploadGratification/BookUploadGratification.Page')
)

const BookWorldPage = React.lazy(
  () => import('~/src/Pages/BookWorld/BookWorld.Page')
)

const BookReadOutcomePage = React.lazy(
  () => import('~/src/Pages/BookReadOutcome/BookReadOutcome.Page')
)

const BookLibraryPage = React.lazy(
  () => import('~/src/Pages/BookLibrary/BookLibrary.Page')
)

const FriendListPage = React.lazy(
  () => import('~/src/Pages/FriendList/FriendList.Page')
)

const FriendAddPage = React.lazy(
  () => import('~/src/Pages/FriendAdd/FriendAdd.Page')
)

const AchievementsPage = React.lazy(
  () => import('~/src/Pages/Achievements/Achievements.Page')
)

const CouponListPage = React.lazy(
  () => import('~/src/Pages/CouponList/CouponList.Page')
)

const CouponNewPage = React.lazy(
  () => import('~/src/Pages/CouponNew/CouponNew.Page')
)

const CouponEditPage = React.lazy(
  () => import('~/src/Pages/CouponEdit/CouponEdit.Page')
)

const SubscriptionListPage = React.lazy(
  () => import('~/src/Pages/SubscriptionList/SubscriptionList.Page')
)

const SubscriptionMemberMgmtPage = React.lazy(
  () => import('~/src/Pages/SubscriptionMemberMgmt/SubscriptionMemberMgmt.Page')
)

const SubscriptionHistoryListPage = React.lazy(
  () =>
    import('~/src/Pages/SubscriptionHistoryList/SubscriptionHistoryList.Page')
)

const NotFoundPage = React.lazy(
  () => import('~/src/Pages/NotFound/NotFound.Page')
)

const routeObj: RouteObject[] = [
  {
    element: <UnauthenticatedLayout />,
    children: [
      {
        path: APP_ROUTES.REGISTER.pathname,
        element: <RegisterPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LOGIN.pathname,
        element: <LoginPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FORGOT_USERNAME_IDENTIFIER_EMAIL.pathname,
        element: <ForgotUsernameIdentifierEmailPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FORGOT_USERNAME_LIST.pathname,
        element: <ForgotUsernameListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FORGOT_PASSWORD_IDENTIFIER_USERNAME.pathname,
        element: <ForgotPasswordIdentifierUsernamePage />
      } as RouteObject,
      {
        path: APP_ROUTES.FORGOT_PASSWORD_SET.pathname,
        element: <ForgotPasswordSetPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    element: <AdminAuthenticatedLayout />,
    loader: adminAuthLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD_ADMIN.pathname,
        element: <DashboardAdminPage />
      } as RouteObject,
      {
        path: APP_ROUTES.USER_LIST.pathname,
        element: <UserListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.USER_PROFILE.pathname,
        element: <UserProfilePage />
      } as RouteObject,
      {
        path: APP_ROUTES.EVENT_LIST.pathname,
        element: <EventListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.EVENT_NEW.pathname,
        element: <EventNewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.EVENT_REVIEW.pathname,
        element: <EventReviewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COUPON_LIST.pathname,
        element: <CouponListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COUPON_NEW.pathname,
        element: <CouponNewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COUPON_EDIT.pathname,
        element: <CouponEditPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    element: <AdminAdultAuthenticatedLayout />,
    loader: adminAdultAuthLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.BOOK_LIST.pathname,
        element: <BookListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_SHOW.pathname,
        element: <BookShowPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_FORM_BOOK_INFO.pathname,
        element: <BookFormBookInfoPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_FORM_QUESTIONS.pathname,
        element: <BookFormQuestionsPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_FORM_REVIEW.pathname,
        element: <BookFormReviewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_UPLOAD_GRATIFICATION.pathname,
        element: <BookUploadGratificationPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_LIST.pathname,
        element: <CompetitionListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_SHOW.pathname,
        element: <CompetitionShowPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PROFILE.pathname,
        element: <ProfilePage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    element: <AdultAuthenticatedLayout />,
    loader: adultAuthLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD_ADULT.pathname,
        element: <DashboardAdultPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_NEW.pathname,
        element: <CompetitionNewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_REVIEW.pathname,
        element: <CompetitionReviewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.GROUP_LIST.pathname,
        element: <GroupListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.GROUP_NEW.pathname,
        element: <GroupNewPage />
      } as RouteObject,
      {
        path: APP_ROUTES.GROUP_SHOW.pathname,
        element: <GroupShowPage />
      } as RouteObject,
      {
        path: APP_ROUTES.SUBSCRIPTION_LIST.pathname,
        element: <SubscriptionListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.SUBSCRIPTION_MEMBER_MGMT.pathname,
        element: <SubscriptionMemberMgmtPage />
      } as RouteObject,
      {
        path: APP_ROUTES.SUBSCRIPTION_HISTORY_LIST.pathname,
        element: <SubscriptionHistoryListPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    element: <ChildAuthenticatedLayout />,
    loader: childAuthLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD_CHILD.pathname,
        element: <DashboardChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PROFILE_CHILD.pathname,
        element: <ProfileChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_WORLD.pathname,
        element: <BookWorldPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_LIST_CHILD.pathname,
        element: <CompetitionListChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_LIST_COMPLETED_CHILD.pathname,
        element: <CompetitionListCompletedChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.EVENT_LIST_CHILD.pathname,
        element: <EventListChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.EVENT_LIST_COMPLETED_CHILD.pathname,
        element: <EventListCompletedChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMPETITION_SHOW_CHILD.pathname,
        element: <CompetitionShowChildPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_READ.pathname,
        element: <BookReadPage />
      } as RouteObject,
      {
        path: APP_ROUTES.BOOK_READ_OUTCOME.pathname,
        element: <BookReadOutcomePage />
      } as RouteObject,

      {
        path: APP_ROUTES.BOOK_LIBRARY.pathname,
        element: <BookLibraryPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FRIEND_LIST.pathname,
        element: <FriendListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FRIEND_ADD.pathname,
        element: <FriendAddPage />
      } as RouteObject,
      {
        path: APP_ROUTES.ACHIEVEMENTS.pathname,
        element: <AchievementsPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    loader: NotFoundLoader,
    errorElement: <ErrorBoundary />,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
