import { createSelector } from '@reduxjs/toolkit'

import { I_USER_STATE } from './TYPES'

export const SLICE_NAME = 'user'

const select = (state: any) => state[SLICE_NAME] as I_USER_STATE

export const getUserReducer = select

export const getUserIdSelector = createSelector(
  select,
  reducer => reducer.id || ''
)

export const getUserFullNameSelector = createSelector(
  select,
  reducer => reducer.fullName || ''
)

export const getUserEmailIdSelector = createSelector(
  select,
  reducer => reducer.emailId || ''
)

export const getUserUsernameSelector = createSelector(
  select,
  reducer => reducer.username || ''
)

export const getUserUserTypeSelector = createSelector(
  select,
  reducer => reducer.userType || ''
)

export const getUserDobSelector = createSelector(
  select,
  reducer => reducer.dob || ''
)

export const getUserLanguageSelector = createSelector(
  select,
  reducer => reducer.language || 'hu'
)

export const getUserAvatarSelector = createSelector(
  select,
  reducer => reducer.avatar || ''
)

export const getUserDeviceIdSelector = createSelector(
  select,
  reducer => reducer.deviceId || ''
)
