import { createSelector } from '@reduxjs/toolkit'

import {
  I_COMPETITION_REDUCER,
  I_GROUPED_COMPETITION,
  T_COMPETITION_STATUS
} from './TYPES'

import { _forEach, _sortBy } from '~/src/Utils/lodash'

export const SLICE_NAME = 'competition'

const select = (state: any): I_COMPETITION_REDUCER => state[SLICE_NAME]

export const getMyCompetitionListReducer = createSelector(select, reducer => {
  switch (reducer.sortBy) {
    case 'ALPHABETICALLY': {
      return _sortCompetitionsBy(
        reducer.competitions,
        ['competitionName'],
        false
      )
    }
    case 'ALPHABETICALLY_REVERSE': {
      return _sortCompetitionsBy(
        reducer.competitions,
        ['competitionName'],
        true
      )
    }
    case 'CREATED_AT': {
      return _sortCompetitionsBy(reducer.competitions, ['createdAt'], false)
    }
    case 'CREATED_AT_REVERSE': {
      return _sortCompetitionsBy(reducer.competitions, ['createdAt'], true)
    }
    case 'MEMBERS_COUNT': {
      return _sortCompetitionsBy(
        reducer.competitions,
        ['memberIds.length'],
        true
      )
    }
    case 'MEMBERS_COUNT_REVERSE': {
      return _sortCompetitionsBy(
        reducer.competitions,
        ['memberIds.length'],
        false
      )
    }
    default: {
      return _sortCompetitionsBy(
        reducer.competitions,
        ['competitionName'],
        false
      )
    }
  }
})

export const getFilterByCompetitionReducer = createSelector(
  select,
  reducer => reducer.filterBy
)

export const getSortByCompetitionReducer = createSelector(
  select,
  reducer => reducer.sortBy
)

export const getSelectedCompetitionReducer = createSelector(
  select,
  reducer => reducer.selectedCompetition
)

export const getSelectedCompetitionMembersReducer = createSelector(
  select,
  (reducer: I_COMPETITION_REDUCER) => {
    const { selectedCompetition } = reducer
    const { members = [] } = selectedCompetition || {}
    return _sortBy(members, ['status', 'fullName'])
  }
)

export const getMyCompetitionInvitesReducer = createSelector(
  select,
  (reducer: I_COMPETITION_REDUCER) => {
    return reducer.invites
  }
)

export const getSelectedCompetitionAnalyticsSelector = createSelector(
  select,
  reducer => reducer.selectedCompetitionAnalytics
)

function _sortCompetitionsBy(
  groups: I_GROUPED_COMPETITION,
  key: any,
  reverse: boolean = false
) {
  const groupedGroups: I_GROUPED_COMPETITION = {
    ONGOING: [],
    UPCOMING: [],
    COMPLETED: []
  }
  _forEach(groups, (value, groupKey) => {
    const sortedGroup = _sortBy(value, key)
    groupedGroups[groupKey as T_COMPETITION_STATUS] = reverse
      ? sortedGroup.reverse()
      : sortedGroup
  })

  return groupedGroups
}

export const getMyCompetitionLeaderboardReducer = createSelector(
  select,
  (reducer: I_COMPETITION_REDUCER) => {
    return reducer.leaderboard
  }
)
