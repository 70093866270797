import { WebHttpRequestOptions } from '@am92/web-http'

import {
  updateDeviceIdServiceName,
  updateDeviceIdTraceActions
} from '../Actions'

import serviceActionCreatorWithTokenRotation from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import { asHttp } from '~/src/Configurations/WebHttp'

async function updateDeviceId(deviceId: string) {
  const options: WebHttpRequestOptions = {
    url: '/users/update-device-id',
    method: 'POST',
    data: { deviceId }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const updateDeviceIdService = serviceActionCreatorWithTokenRotation(
  updateDeviceIdTraceActions,
  updateDeviceId
)

export default updateDeviceIdService
export { updateDeviceIdServiceName }
