import { createSelector } from '@reduxjs/toolkit'

import { I_LANGUAGE_STATE } from './TYPES'

export const SLICE_NAME = 'languague'

const select = (state: any): I_LANGUAGE_STATE => state[SLICE_NAME]

export const getSelectedLanguageSelector = createSelector(
  select,
  languageReducer => languageReducer.selectedLanguage
)
