import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions
} from '../User/Actions'
import {
  setThemeAction,
  setThemefontFamilyAction,
  setThemePaletteAction,
  setThemeSchemeAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import {
  CHILD_THEME,
  DEFAULT_THEME,
  INITIAL_STATE,
  T_THEME_REDUCER
} from './TYPES'

import { USER_TYPE_CONSTANTS } from '~/src/Constants/USER'

const sliceOptions: CreateSliceOptions<T_THEME_REDUCER> = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<T_THEME_REDUCER>): void => {
    builder.addCase(setThemeSchemeAction, (state, { payload }) => {
      state.scheme = payload
    })

    builder.addCase(setThemePaletteAction, (state, { payload }) => {
      state.palette = payload
    })

    builder.addCase(setThemefontFamilyAction, (state, { payload }) => {
      state.fontFamily = payload
    })

    builder.addCase(setThemeAction, () => {
      return { ...DEFAULT_THEME }
    })

    builder.addCase(loginTraceActions.success, themeSetter)
    builder.addCase(getMeTraceActions.success, themeSetter)
    builder.addCase(registerUserActions.success, themeSetter)
    builder.addCase(logoutTraceActions.success, themeSetter)
    builder.addCase(logoutTraceActions.error, themeSetter)
    builder.addCase(loginWithRefreshTokenTraceActions.error, themeSetter)
    builder.addCase(deleteAccountTraceActions.success, themeSetter)
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer

function themeSetter(state: T_THEME_REDUCER, { payload }: any) {
  const { userType } = payload?.user || payload || {}

  if (userType === USER_TYPE_CONSTANTS.CHILD) {
    return { ...CHILD_THEME }
  } else {
    return { ...DEFAULT_THEME }
  }
}
