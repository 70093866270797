import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions
} from '../User/Actions'
import {
  searchAuthorsTraceActions,
  searchBookInLibraryTraceActions,
  searchBooksTraceActions,
  searchChildrenTraceActions,
  searchGroupsTraceActions,
  searchHashtagsTraceActions,
  searchPublishersTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_SEARCH_REDUCER } from './TYPES'

import { _map } from '~/src/Utils/lodash'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<T_SEARCH_REDUCER>): void => {
    builder.addCase(
      searchChildrenTraceActions.success,
      (state, { payload }) => {
        state.children = _map(payload, _sanitizeChild)
      }
    )

    builder.addCase(searchBooksTraceActions.success, (state, { payload }) => {
      state.books = payload
    })

    builder.addCase(searchGroupsTraceActions.success, (state, { payload }) => {
      state.groups = payload
    })

    builder.addCase(
      searchHashtagsTraceActions.success,
      (state, { payload }) => {
        state.hashtags = payload
      }
    )

    builder.addCase(searchAuthorsTraceActions.success, (state, { payload }) => {
      state.authors = payload
    })

    builder.addCase(
      searchPublishersTraceActions.success,
      (state, { payload }) => {
        state.publishers = payload
      }
    )

    builder.addCase(
      searchBookInLibraryTraceActions.success,
      (state, { payload }) => {
        state.bookOwners = payload
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(getMeTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer

function _sanitizeChild(child: any) {
  const initials = (child.fullName || child.user.fullName)
    .split(' ')
    .map((part: string) => part[0])
    .join('')
    .substring(0, 2)
    .toUpperCase()

  if (child.user) {
    child.user.initials = initials
  } else {
    child.initials = initials
  }

  return child
}
