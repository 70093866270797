import React, { PureComponent } from 'react'
import {
  DsImage,
  DsImageProps,
  DsInputBase,
  DsMenuItem,
  DsRemixIcon,
  DsRemixIconProps,
  DsSelect,
  DsSelectProps,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withPageConstants, {
  IWithPageConstantsProps
} from '../Hocs/withPageConstants'

import {
  LANGUAGE_FLAG_MAP,
  LANGUAGE_MAP,
  LANGUAGE_SELECT_OPTIONS,
  T_LANGUAGE_CODES
} from '../Constants/LANGUAGE'

const IconComponent = (props: Omit<DsRemixIconProps, 'ref'>) => {
  return (
    <DsRemixIcon
      {...props}
      className={`${props.className} ri-arrow-down-s-fill`}
    />
  )
}

interface ILanguageSelectorProps
  extends IWithPageConstantsProps<any>,
    DsSelectProps {
  selectedValueImageProps?: DsImageProps
}

class LanguageSelector extends PureComponent<ILanguageSelectorProps> {
  handleLanguageChange = (event: any) => {
    const { onChangeLanguage } = this.props
    const { target } = event
    const { value } = target

    onChangeLanguage(value)
  }

  renderLanguageOptions = () => {
    return LANGUAGE_SELECT_OPTIONS.map((languageOption, index) => (
      <DsMenuItem value={languageOption.value} key={index}>
        {this.renderSelectedLanguage(languageOption.value)}
      </DsMenuItem>
    ))
  }

  renderSelectedLanguage = (value: unknown) => {
    const { selectedValueImageProps } = this.props
    const languageSrcset = LANGUAGE_FLAG_MAP[value as T_LANGUAGE_CODES]
    const languageLabel = LANGUAGE_MAP[value as T_LANGUAGE_CODES]
    return (
      <DsStack
        direction='row'
        alignItems='center'
        spacing='var(--ds-spacing-quickFreeze)'
      >
        <DsImage
          {...selectedValueImageProps}
          srcSet={languageSrcset}
          alt={`${languageLabel} Flag`}
          style={{ display: 'block', ...selectedValueImageProps?.style }}
          WrapperProps={{ sx: { width: 'auto' } }}
        />
        <DsTypography variant='bodyRegularSmall'>{languageLabel}</DsTypography>
      </DsStack>
    )
  }

  render() {
    const {
      selectedLanguage,
      selectedValueImageProps,
      PAGE_CONSTANTS,
      onChangeLanguage,
      ...selectProps
    } = this.props

    return (
      <DsSelect
        value={selectedLanguage}
        renderValue={this.renderSelectedLanguage}
        onChange={this.handleLanguageChange}
        IconComponent={IconComponent}
        input={
          <DsInputBase
            sx={{
              borderRadius: '32px',
              px: 'var(--ds-spacing-frostbite)'
            }}
          />
        }
        {...selectProps}
      >
        {this.renderLanguageOptions()}
      </DsSelect>
    )
  }
}

export default withPageConstants({}, LanguageSelector)
