import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  appendBookReadResultAction,
  decreaseBookReadActiveIndexAction,
  getBookForReadingTraceActions,
  increaseBookReadActiveIndexAction,
  resetBookReadAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_BOOK_READ_REDUCER, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<I_BOOK_READ_REDUCER>
  ): void => {
    builder.addCase(resetBookReadAction, () => ({ ...INITIAL_STATE }))

    builder.addCase(
      getBookForReadingTraceActions.success,
      (state, { payload }) => ({
        bookForReading: payload,
        readForm: { ...INITIAL_STATE.readForm }
      })
    )

    builder.addCase(appendBookReadResultAction, (state, { payload }) => {
      const { readForm } = state
      const { index, result } = payload
      const { resultMap, ...restForm } = readForm
      resultMap[index] = { ...result }

      state.readForm = { ...restForm, resultMap: { ...resultMap } }
    })

    builder.addCase(increaseBookReadActiveIndexAction, state => {
      const { readForm } = state
      const { activeStep, ...restForm } = readForm

      state.readForm = {
        ...restForm,
        activeStep: activeStep + 1,
        swipeDirection: 'x'
      }
    })

    builder.addCase(decreaseBookReadActiveIndexAction, state => {
      const { readForm } = state
      const { activeStep, ...restForm } = readForm

      state.readForm = {
        ...restForm,
        activeStep: activeStep - 1,
        swipeDirection: 'x-reverse'
      }
    })

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
