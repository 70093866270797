import { DsImageProps } from '@am92/react-design-system'

import MASCOT_BIRD_VERSION_1 from './ASSETS/MASCOT/MASCOT_BIRD_VERSION_1'
import MASCOT_BIRD_VERSION_2 from './ASSETS/MASCOT/MASCOT_BIRD_VERSION_2'
import MASCOT_BIRD_VERSION_3 from './ASSETS/MASCOT/MASCOT_BIRD_VERSION_3'
import MASCOT_BIRD_VERSION_4 from './ASSETS/MASCOT/MASCOT_BIRD_VERSION_4'
import MASCOT_BIRD_VERSION_5 from './ASSETS/MASCOT/MASCOT_BIRD_VERSION_5'
import MASCOT_EGG from './ASSETS/MASCOT/MASCOT_EGG'

export type T_MASCOT_TYPE =
  | 'EGG'
  | 'BIRD_VERSION_1'
  | 'BIRD_VERSION_2'
  | 'BIRD_VERSION_3'
  | 'BIRD_VERSION_4'
  | 'BIRD_VERSION_5'

export type T_USER_LEVEL = {
  currentLevel: number
  nextLevel: number
  mascotType: T_MASCOT_TYPE
  mascotLevel: number
  exp: {
    max: number
    current: number
    levelUpIn: number
    value: number
  }
}

export const INCONSISTENT_USER_LEVEL_LIMIT = 7

export const INCONSISTENT_USER_LEVEL_MAX_POINTS = 2000

export const INCONSISTENT_USER_LEVEL_MAX_POINTS_ARRAY: number[] = [
  0, 250, 500, 850, 1200, 1600, 2000
]

export const INCONSISTENT_USER_LEVEL_MAX_POINTS_MAP: { [key: number]: number } =
  {
    0: 0,
    1: 250,
    2: 500,
    3: 850,
    4: 1200,
    5: 1600,
    6: 2000
  }

export const CONSISTENT_USER_LEVEL_UP_POINTS = 500

export const MASCOT_TYPE: { [key in T_MASCOT_TYPE]: key } = {
  EGG: 'EGG',
  BIRD_VERSION_1: 'BIRD_VERSION_1',
  BIRD_VERSION_2: 'BIRD_VERSION_2',
  BIRD_VERSION_3: 'BIRD_VERSION_3',
  BIRD_VERSION_4: 'BIRD_VERSION_4',
  BIRD_VERSION_5: 'BIRD_VERSION_5'
}

export const USER_LEVEL_MASCOT_TYPE_MAP: { [key: number]: T_MASCOT_TYPE } = {
  1: MASCOT_TYPE.EGG,
  2: MASCOT_TYPE.EGG,
  3: MASCOT_TYPE.BIRD_VERSION_1,
  4: MASCOT_TYPE.BIRD_VERSION_1,
  5: MASCOT_TYPE.BIRD_VERSION_1,
  6: MASCOT_TYPE.BIRD_VERSION_1,
  7: MASCOT_TYPE.BIRD_VERSION_2,
  8: MASCOT_TYPE.BIRD_VERSION_2,
  9: MASCOT_TYPE.BIRD_VERSION_2,
  10: MASCOT_TYPE.BIRD_VERSION_3,
  11: MASCOT_TYPE.BIRD_VERSION_3,
  12: MASCOT_TYPE.BIRD_VERSION_3,
  13: MASCOT_TYPE.BIRD_VERSION_4,
  14: MASCOT_TYPE.BIRD_VERSION_4,
  15: MASCOT_TYPE.BIRD_VERSION_4,
  16: MASCOT_TYPE.BIRD_VERSION_5
}

export const MASCOT_TYPE_MIN_LEVEL_MAP: { [key in T_MASCOT_TYPE]: number } =
  Object.keys(USER_LEVEL_MASCOT_TYPE_MAP).reduce(
    (obj, level) => {
      const mascotType = USER_LEVEL_MASCOT_TYPE_MAP[Number(level)]
      obj[mascotType] = obj[mascotType] || Number(level)
      return obj
    },
    {} as { [key in T_MASCOT_TYPE]: number }
  )

export const USER_LEVEL_MASCOT_LEVEL_MAP: { [key: number]: number } = {
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 2,
  6: 2,
  7: 3,
  8: 3,
  9: 3,
  10: 4,
  11: 4,
  12: 4,
  13: 5,
  14: 5,
  15: 5
}

export const MASCOT_LEVEL_MASCOT_TYPE_MAP: { [key: number]: T_MASCOT_TYPE } = {
  1: MASCOT_TYPE.EGG,
  2: MASCOT_TYPE.BIRD_VERSION_1,
  3: MASCOT_TYPE.BIRD_VERSION_2,
  4: MASCOT_TYPE.BIRD_VERSION_3,
  5: MASCOT_TYPE.BIRD_VERSION_4,
  6: MASCOT_TYPE.BIRD_VERSION_5
}

export const MASCOT_TYPE_IMAGE: {
  [key in T_MASCOT_TYPE]: DsImageProps['srcSet']
} = {
  EGG: MASCOT_EGG,
  BIRD_VERSION_1: MASCOT_BIRD_VERSION_1,
  BIRD_VERSION_2: MASCOT_BIRD_VERSION_2,
  BIRD_VERSION_3: MASCOT_BIRD_VERSION_3,
  BIRD_VERSION_4: MASCOT_BIRD_VERSION_4,
  BIRD_VERSION_5: MASCOT_BIRD_VERSION_5
}
