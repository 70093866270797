import { I_USER_STATE } from '../User/TYPES'
import { I_USER_BANK_STATE } from '../UserBank/TYPES'

export type T_PLATFORM_USER_TYPES = 'CHILD' | 'ADULT'
export type T_PLATFORM_USER_FILTERS = 'ALL' | T_PLATFORM_USER_TYPES
export type T_PLATFORM_USER_SORTS =
  | 'ALPHABETICALLY'
  | 'ALPHABETICALLY_REVERSE'
  | 'CREATED_AT'
  | 'CREATED_AT_REVERSE'

export interface I_PLATFORM_USER extends I_USER_STATE {
  age?: number
  userBank?: I_USER_BANK_STATE
  competitionCount?: number
  bookCount?: number
}

export type I_GROUPED_PLATFORM_USER = {
  [key in T_PLATFORM_USER_TYPES]: I_PLATFORM_USER[]
}

export interface I_PLATFORM_USER_REDUCER {
  platformUsers: I_GROUPED_PLATFORM_USER
  filterBy: T_PLATFORM_USER_FILTERS
  sortBy: T_PLATFORM_USER_SORTS
  selectedUser: I_PLATFORM_USER
}

export const INITIAL_STATE: I_PLATFORM_USER_REDUCER = {
  platformUsers: {
    CHILD: [],
    ADULT: []
  },
  filterBy: 'ALL',
  sortBy: 'ALPHABETICALLY',
  selectedUser: {
    id: '',
    fullName: '',
    emailId: '',
    username: '',
    userType: 'CHILD',
    dob: '',
    language: 'hu',
    avatar: ''
  }
}
