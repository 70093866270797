import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  bookFeedbackTraceActions,
  bookMarkReadTraceActions
} from '../BookRead/Actions'
import {
  deleteAccountTraceActions,
  getUserBankTraceActions,
  registerUserActions
} from '../User/Actions'
import { SLICE_NAME } from './Selectors'
import { I_USER_BANK_STATE, INITIAL_STATE } from './TYPES'

import { userLevelCalculator } from '~/src/Utils/userLevelCalculator'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      registerUserActions.success,
      (state: I_USER_BANK_STATE, { payload }) => {
        const { userBank } = payload
        const userLevel = userLevelCalculator(userBank?.pointsEarned)
        return { ...userBank, userLevel }
      }
    )

    builder.addCase(
      loginTraceActions.success,
      (state: I_USER_BANK_STATE, { payload }) => {
        const { userBank } = payload
        const userLevel = userLevelCalculator(userBank?.pointsEarned)
        return { ...userBank, userLevel }
      }
    )

    builder.addCase(
      bookMarkReadTraceActions.success,
      (state: I_USER_BANK_STATE, { payload }) => {
        const { userBank } = payload
        const userLevel = userLevelCalculator(userBank?.pointsEarned)
        return { ...userBank, userLevel }
      }
    )
    builder.addCase(
      bookFeedbackTraceActions.success,
      (state: I_USER_BANK_STATE, { payload }) => {
        const { userBank } = payload
        const userLevel = userLevelCalculator(userBank?.pointsEarned)
        return { ...userBank, userLevel }
      }
    )
    builder.addCase(
      getUserBankTraceActions.success,
      (state: I_USER_BANK_STATE, { payload }) => {
        const userLevel = userLevelCalculator(payload?.pointsEarned)
        return { ...payload, userLevel }
      }
    )

    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
