import { createSelector } from '@reduxjs/toolkit'

import { I_COUPON_REDUCER } from './TYPES'

export const SLICE_NAME = 'coupon'

const select = (state: any) => state[SLICE_NAME] as I_COUPON_REDUCER

export const getCouponReducer = select

export const getCouponListSelector = createSelector(
  select,
  reducer => reducer.list
)

export const getSelectedCouponSelector = createSelector(
  select,
  reducer => reducer.selected
)
