import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'

interface I_AUTH_STATE {
  isLoggedIn: boolean
  loggedInAt?: string
  accessToken: string
  refreshToken: string
}

const INITIAL_STATE: I_AUTH_STATE = {
  isLoggedIn: false,
  accessToken: '',
  refreshToken: ''
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      registerUserActions.success,
      (state: I_AUTH_STATE, { payload }) => {
        const { user } = payload
        const { tokens } = user
        const loggedInAt = new Date().toISOString()
        return { isLoggedIn: true, ...tokens, loggedInAt }
      }
    )

    builder.addCase(
      loginTraceActions.success,
      (state: I_AUTH_STATE, { payload }) => {
        const { user } = payload
        const { tokens } = user
        const loggedInAt = new Date().toISOString()
        return { isLoggedIn: true, ...tokens, loggedInAt }
      }
    )

    builder.addCase(
      loginWithRefreshTokenTraceActions.success,
      (state: I_AUTH_STATE, { payload }) => {
        const { tokens } = payload
        const loggedInAt = new Date().toISOString()
        return { isLoggedIn: true, ...tokens, loggedInAt }
      }
    )

    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
