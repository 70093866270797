import { T_FRIEND, T_FRIEND_USER } from '~/src/Entities/Friend/Friend.Types'

export interface I_FRIEND_REDUCER {
  friends: T_FRIEND_USER[]
  requests: T_FRIEND[]
  pendingApprovals: T_FRIEND[]
}

export const INITIAL_STATE: I_FRIEND_REDUCER = {
  friends: [],
  requests: [],
  pendingApprovals: []
}
