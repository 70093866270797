import traceActionsCreator from '../traceActionsCreator'

// export const sampleAction = createAction('Search/sample')

export const searchChildrenServiceName = 'Search/searchChildren'
export const searchChildrenTraceActions = traceActionsCreator(
  searchChildrenServiceName
)

export const searchBooksServiceName = 'Search/searchBooks'
export const searchBooksTraceActions = traceActionsCreator(
  searchBooksServiceName
)

export const searchGroupsServiceName = 'Search/searchGroups'
export const searchGroupsTraceActions = traceActionsCreator(
  searchGroupsServiceName
)

export const searchHashtagsServiceName = 'Search/searchHashtags'
export const searchHashtagsTraceActions = traceActionsCreator(
  searchHashtagsServiceName
)

export const searchAuthorsServiceName = 'Search/searchAuthors'
export const searchAuthorsTraceActions = traceActionsCreator(
  searchAuthorsServiceName
)

export const searchPublishersServiceName = 'Search/searchPublishers'
export const searchPublishersTraceActions = traceActionsCreator(
  searchPublishersServiceName
)

export const searchBookInLibraryServiceName = 'Search/searchBookInLibrary'
export const searchBookInLibraryTraceActions = traceActionsCreator(
  searchBookInLibraryServiceName
)
