import React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'

import { APP_MODE } from './Configurations/env'
import AppStore, { PersistedAppStore } from '~/src/Configurations/AppStore'

// import '~/public/serviceWorkerRegistration'

if (APP_MODE === 'CORDOVA') {
  // Dynamically load cordova
  const cordova = document.createElement('script')
  cordova.setAttribute('type', 'text/javascript')
  cordova.setAttribute('src', 'cordova.js')
  cordova.onload = () => {
    if (window.cordova) {
      document.addEventListener('deviceready', renderApp, false)
    } else {
      renderApp()
    }
  }
  cordova.onerror = renderApp
  document.getElementsByTagName('head')[0].appendChild(cordova)
} else {
  renderApp()
}

function renderApp() {
  const container = document.getElementById('app')
  ReactDom.render(
    <Provider store={AppStore}>
      <PersistGate persistor={PersistedAppStore} onBeforeLift={() => undefined}>
        {(persisted: boolean) => <App persisted={persisted} />}
      </PersistGate>
    </Provider>,
    container
  )
}
