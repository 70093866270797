import React, { Component, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { RouterProvider } from 'react-router-dom'
import {
  DsCssBaseline,
  DsNotistackProvider,
  Experimental_CssVarsProvider as CssVarsProvider,
  getTheme
} from '@am92/react-design-system'
import { WEB_HTTP_CONTEXT } from '@am92/web-http'

import FcmPermission from './Components/Fcm/FcmPermission'
import ThemeSetter from './Components/ThemeSetter'
import Loader from '~/src/Components/Loader'

import {
  getAccessTokenSelector,
  getIsLoggedInSelector,
  getRefreshTokenSelector
} from './Redux/Auth/Selectors'
import logoutService from './Redux/Auth/Services/logout.Service'
import { getSelectedLanguageSelector } from './Redux/Language/Selectors'
import { getThemeReducer } from './Redux/Theme/Selectors'
import getMeService from './Redux/User/Services/getMe.Service'
import updateDeviceIdService from './Redux/User/Services/updateDeviceId.Service'
import performHandshake from '~/src/Services/performHandshake'

import { LOCALE_MAP } from './Constants/LANGUAGE'
import { THEME_MODE_STORAGE_KEY } from '~/src/Constants/THEME'
import { asHttp } from './Configurations/WebHttp'
import getAppRouter from '~/src/Configurations/getAppRouter'

interface Props extends PropsFromRedux {
  persisted: boolean
}

type State = {
  hasError: boolean
  initialized: boolean
}

const DefaultState: State = {
  hasError: false,
  initialized: false
}

class App extends Component<Props, State> {
  state = DefaultState

  constructor(props: Props) {
    super(props)

    // If you dont want to respect user selected theme
    // and set default theme to one set in THEME constants then uncomment the line
    // this.resetUserThemeToDefault()
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.persisted) {
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, this.props.accessToken)
      asHttp.context.set(
        WEB_HTTP_CONTEXT.REFRESH_TOKEN,
        this.props.refreshToken
      )
    }

    if (!prevProps.persisted && this.props.persisted) {
      this.initialize()
    }
  }

  initialize = async () => {
    try {
      const { isLoggedIn, actions } = this.props
      await performHandshake()
      if (isLoggedIn) {
        const response = await actions.getMeService()

        if (response?._isWebHttpError || response?.status === 'DELETED') {
          if (window.cordova) {
            await actions.updateDeviceIdService('')
          }
          actions.logoutService()
        }
      }
      this.setState({ initialized: true })
    } catch (error) {
      this.setState({ hasError: true, initialized: true })
    }
  }

  resetUserThemeToDefault = () => {
    window.localStorage.removeItem(THEME_MODE_STORAGE_KEY)
  }

  render() {
    const { theme, selectedLanguage } = this.props

    const { palette, fontFamily } = theme

    let children = <Loader />
    const AppTheme = getTheme(
      palette,
      fontFamily,
      LOCALE_MAP[selectedLanguage] || []
    )

    if (this.state.initialized) {
      const router = getAppRouter()
      children = (
        <>
          <FcmPermission />
          <RouterProvider router={router} />
        </>
      )
    }

    return (
      <CssVarsProvider theme={AppTheme} modeStorageKey={THEME_MODE_STORAGE_KEY}>
        <ThemeSetter />
        <DsCssBaseline enableColorScheme>
          <DsNotistackProvider
            preventDuplicate
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </DsNotistackProvider>
        </DsCssBaseline>
      </CssVarsProvider>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isLoggedIn = getIsLoggedInSelector(state)
  const accessToken = getAccessTokenSelector(state)
  const refreshToken = getRefreshTokenSelector(state)
  const theme = getThemeReducer(state)
  const selectedLanguage = getSelectedLanguageSelector(state)

  return {
    isLoggedIn,
    accessToken,
    refreshToken,
    theme,
    selectedLanguage
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getMeService: () => dispatch(getMeService()),
    logoutService: () => dispatch(logoutService()),
    updateDeviceIdService: (deviceId: string) =>
      dispatch(updateDeviceIdService(deviceId))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(App)
