import { I_USER_STATE } from '../User/TYPES'

export type T_BOOK_STATUS = 'IN_REVIEW' | 'APPROVED' | 'REJECTED'
export type T_BOOK_FILTERS = 'ALL' | T_BOOK_STATUS
export type T_BOOK_SORTS =
  | 'ALPHABETICALLY'
  | 'ALPHABETICALLY_REVERSE'
  | 'CREATED_AT'
  | 'CREATED_AT_REVERSE'

export type T_BOOK_CONTRIBUTORS = 'ME' | 'ALL'

export interface I_BOOK_QUESTION {
  question: string
  answer: string
  option1: string
  option2: string
}
export interface I_BOOK {
  id: string
  bookName: string
  genre: string
  isSeries: boolean
  seriesNo: number
  seriesName: string
  language: string
  minAge: number
  rating?: number
  authors: string[]
  publisher: string
  noOfPages: number
  readTimeInMins: number
  coverImage: string
  tags: string[]
  questions: I_BOOK_QUESTION[]

  createdBy: string
  createdAt: string
  updatedAt: string
  status: T_BOOK_STATUS
  reviewedOn?: string
  isRead?: boolean
  pointsEarned?: number
  creator?: Pick<I_USER_STATE, 'id' | 'username' | 'fullName'>
}

export type I_GROUPED_BOOK = { [key: string]: I_BOOK[] }

export interface I_BOOK_SEARCH_PROPS {
  searchString?: string
  language: string
  minAge?: string
  maxAge?: string
  genre: string[]
  authors: string[]
  tags: string[]
  status: T_BOOK_STATUS
  contributor: T_BOOK_CONTRIBUTORS
}

export interface I_BOOK_REDUCER {
  books: I_GROUPED_BOOK
  searchProps: I_BOOK_SEARCH_PROPS
  book: I_BOOK
  recentRead: I_BOOK[]
}

export const INITIAL_STATE: I_BOOK_REDUCER = {
  books: {},
  searchProps: {
    searchString: '',
    language: 'hu',
    minAge: '',
    maxAge: '',
    genre: [],
    authors: [],
    tags: [],
    contributor: 'ALL',
    status: 'APPROVED'
  },
  book: {
    id: '',
    bookName: '',
    genre: '',
    isSeries: false,
    seriesNo: 0,
    seriesName: '',
    language: '',
    minAge: 0,
    authors: [],
    publisher: '',
    noOfPages: 0,
    readTimeInMins: 0,
    coverImage: '',
    tags: [],
    questions: [],
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    status: 'IN_REVIEW'
  },
  recentRead: []
}
