import { DsPalette, SupportedColorScheme } from '@am92/react-design-system'

import { APP_TITLE } from '~/src/Configurations/env'

export const PALETTE: DsPalette = {
  primary: '#EB6B42',
  secondary100: '#0A9474',
  secondary80: '#0A9474',
  secondary60: '#0A9474',
  secondary40: '#0A9474'
}

export const FONT_FAMILY: string = 'Noto'

export const DEFAULT_THEME_MODE: SupportedColorScheme = 'light'

export const THEME_MODE_STORAGE_KEY: string = `${APP_TITLE.replaceAll(
  ' ',
  '-'
)}-mode`
