const HU_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/hu.png?quality=100',
      import.meta.url
    ).href,
    alt: 'HU',
    as: 'image/png'
  }
]

export default HU_FLAG_IMAGE
