import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { T_COMPETITION_FILTERS, T_COMPETITION_SORTS } from './TYPES'

export const competitionListServiceName = 'Competition/list'
export const competitionListActions = traceActionsCreator(
  competitionListServiceName
)

export const setCompetitionListSortBy = createAction<T_COMPETITION_SORTS>(
  'Competition/setSortBy'
)
export const setCompetitionListFilterBy = createAction<T_COMPETITION_FILTERS>(
  'Competition/setFilterBy'
)

export const competitionFindByIdServiceName = 'Competition/findById'
export const competitionFindByIdActions = traceActionsCreator(
  competitionFindByIdServiceName
)

export const competitionAnalyticsServiceName =
  'Competition/competitionAnalytics'
export const competitionAnalyticsActions = traceActionsCreator(
  competitionAnalyticsServiceName
)

export const competitionCreateServiceName = 'Competition/competitionCreate'
export const competitionCreateActions = traceActionsCreator(
  competitionCreateServiceName
)

export const competitionResendInviteServiceName =
  'Competition/competitionResendInvite'
export const competitionResendInviteActions = traceActionsCreator(
  competitionResendInviteServiceName
)

export const competitionRemoveMembersServiceName =
  'Competition/competitionRemoveMembers'
export const competitionRemoveMembersActions = traceActionsCreator(
  competitionRemoveMembersServiceName
)

export const competitionAddMembersServiceName =
  'Competition/competitionAddMembers'
export const competitionAddMembersActions = traceActionsCreator(
  competitionAddMembersServiceName
)

export const competitionMyInvitesServiceName =
  'Competition/competitionMyInvites'
export const competitionMyInvitesTraceActions = traceActionsCreator(
  competitionMyInvitesServiceName
)

export const reviewCompetitionInviteServiceName =
  'Competition/reviewCompetitionInvite'
export const reviewCompetitionInviteTraceActions = traceActionsCreator(
  reviewCompetitionInviteServiceName
)

export const joinCompetitionServiceName = 'Competition/joinCompetition'
export const joinCompetitionTraceActions = traceActionsCreator(
  joinCompetitionServiceName
)

export const competitionExistsServiceName = 'Competition/competitionExists'
export const competitionExistsTraceActions = traceActionsCreator(
  competitionExistsServiceName
)

export const getCompetitionLeaderboardServiceName =
  'Competition/getCompetitionLeaderboard'
export const getCompetitionLeaderboardTraceActions = traceActionsCreator(
  getCompetitionLeaderboardServiceName
)

export const generateCompetitionFileUploadUrlsServiceName =
  'Competition/generateFileUploadUrls'
export const generateCompetitionFileUploadUrlsTraceActions =
  traceActionsCreator(generateCompetitionFileUploadUrlsServiceName)

export const uploadCompetitionCoverServiceName =
  'Competition/uploadCompetitionCover'
export const uploadCompetitionCoverActions = traceActionsCreator(
  uploadCompetitionCoverServiceName
)

export const getProgressAnalyticsServiceName =
  'Competition/getProgressAnalytics'
export const getProgressAnalyticsTraceActions = traceActionsCreator(
  getProgressAnalyticsServiceName
)

export const updateCompetitionByIdServiceName =
  'Competition/updateCompetitionById'
export const updateCompetitionByIdTraceActions = traceActionsCreator(
  updateCompetitionByIdServiceName
)
