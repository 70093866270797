import { createSelector } from '@reduxjs/toolkit'

import { I_SUBSCRIPTION_STATE } from './TYPES'

export const SLICE_NAME = 'subscription'

const select = (state: any) => state[SLICE_NAME] as I_SUBSCRIPTION_STATE

export const getSubscriptionReducer = select

export const getSubscriptionListSubscriptionSelector = createSelector(
  select,
  (reducer: I_SUBSCRIPTION_STATE) => reducer.subscriptions
)

export const getSelectedSubscriptionSelector = createSelector(
  select,
  ({ selected, selectedTenure }: I_SUBSCRIPTION_STATE) => ({
    selected,
    selectedTenure
  })
)

export const getCouponSubscriptionSelector = createSelector(
  select,
  (reducer: I_SUBSCRIPTION_STATE) => reducer.coupon
)

export const getOrderHistorySubscriptionSelector = createSelector(
  select,
  (reducer: I_SUBSCRIPTION_STATE) => reducer.orderHistory
)
