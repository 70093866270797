import { combineReducers, ReducersMapObject } from 'redux'

import AnalyticsReducer from './Analytics/Reducer'
import { SLICE_NAME as AnalyticsSliceName } from './Analytics/Selectors'
import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'
import BookReducer from './Book/Reducer'
import { SLICE_NAME as BookSliceName } from './Book/Selectors'
import BookFormReducer from './BookForm/Reducer'
import { SLICE_NAME as BookFormSliceName } from './BookForm/Selectors'
import BookReadReducer from './BookRead/Reducer'
import { SLICE_NAME as BookReadSliceName } from './BookRead/Selectors'
import CompetitionReducer from './Competition/Reducer'
import { SLICE_NAME as CompetitionSliceName } from './Competition/Selectors'
import CompetitionFormReducer from './CompetitionForm/Reducer'
import { SLICE_NAME as CompetitionFormSliceName } from './CompetitionForm/Selectors'
import CouponReducer from './Coupon/Reducer'
import { SLICE_NAME as CouponSliceName } from './Coupon/Selectors'
import ForgotCredentialsReducer from './ForgotCredentials/Reducer'
import { SLICE_NAME as ForgotCredentialsSliceName } from './ForgotCredentials/Selectors'
import FriendReducer from './Friend/Reducer'
import { SLICE_NAME as FriendSliceName } from './Friend/Selectors'
import FunFactReducer from './FunFact/Reducer'
import { SLICE_NAME as FunFactSliceName } from './FunFact/Selectors'
import GroupReducer from './Group/Reducer'
import { SLICE_NAME as GroupSliceName } from './Group/Selectors'
import LanguageReducer from './Language/Reducer'
import { SLICE_NAME as LanguageSliceName } from './Language/Selectors'
import LibraryReducer from './Library/Reducer'
import { SLICE_NAME as LibrarySliceName } from './Library/Selectors'
import PlatformUsersReducer from './PlatformUsers/Reducer'
import { SLICE_NAME as PlatformUsersSliceName } from './PlatformUsers/Selectors'
import SearchReducer from './Search/Reducer'
import { SLICE_NAME as SearchSliceName } from './Search/Selectors'
import SettingsReducer from './Settings/Reducer'
import { SLICE_NAME as SettingsSliceName } from './Settings/Selectors'
import SubscriptionReducer from './Subscription/Reducer'
import { SLICE_NAME as SubscriptionSliceName } from './Subscription/Selectors'
import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'
import UserReducer from './User/Reducer'
import { SLICE_NAME as UserSliceName } from './User/Selectors'
import UserBadgesReducer from './UserBadges/Reducer'
import { SLICE_NAME as UserBadgesSliceName } from './UserBadges/Selectors'
import UserBankReducer from './UserBank/Reducer'
import { SLICE_NAME as UserBankSliceName } from './UserBank/Selectors'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'

const reducers: ReducersMapObject = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [LanguageSliceName]: LanguageReducer,
  [UserSliceName]: UserReducer,
  [ForgotCredentialsSliceName]: ForgotCredentialsReducer,
  [BookFormSliceName]: BookFormReducer,
  [BookSliceName]: BookReducer,
  [GroupSliceName]: GroupReducer,
  [CompetitionSliceName]: CompetitionReducer,
  [CompetitionFormSliceName]: CompetitionFormReducer,
  [SearchSliceName]: SearchReducer,
  [PlatformUsersSliceName]: PlatformUsersReducer,
  [BookReadSliceName]: BookReadReducer,
  [ThemeSliceName]: ThemeReducer,
  [UserBankSliceName]: UserBankReducer,
  [UserBadgesSliceName]: UserBadgesReducer,
  [AnalyticsSliceName]: AnalyticsReducer,
  [LibrarySliceName]: LibraryReducer,
  [SubscriptionSliceName]: SubscriptionReducer,
  [CouponSliceName]: CouponReducer,
  [FunFactSliceName]: FunFactReducer,
  [SettingsSliceName]: SettingsReducer,
  [FriendSliceName]: FriendReducer
}

const persistedReducers: any[] = [
  ThemeSliceName,
  AuthSliceName,
  UserSliceName,
  SettingsSliceName,
  BookFormSliceName
]

export default combineReducers(reducers)
export { persistedReducers }
