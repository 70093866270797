import { useEffect } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { To } from 'react-router-dom'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import { getIsLoggedInSelector } from '../../Redux/Auth/Selectors'
import updateDeviceIdService from '../../Redux/User/Services/updateDeviceId.Service'
import { getUserDeviceIdSelector } from '~/src/Redux/User/Selectors'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import { T_NOTIF_PAGE_TYPE } from '~/src/Entities/Notification/Notification.Types'

const NOTIF_TYPE_URL_MAP: Record<T_NOTIF_PAGE_TYPE, To> = {
  BOOK_REVIEW: APP_ROUTES.BOOK_FORM_REVIEW.pathname,
  BOOK_SHOW: APP_ROUTES.BOOK_SHOW.pathname,
  COMPETITION_INVITE: APP_ROUTES.COMPETITION_LIST_CHILD.pathname,
  COMPETITION_SHOW: APP_ROUTES.COMPETITION_SHOW.pathname,
  COMPETITION_SHOW_CHILD: APP_ROUTES.COMPETITION_SHOW_CHILD.pathname,
  EVENT_LIST: APP_ROUTES.EVENT_LIST.pathname,
  EVENT_LIST_CHILD: APP_ROUTES.EVENT_LIST_CHILD.pathname,
  FRIEND_REQUEST_LIST: APP_ROUTES.FRIEND_LIST.pathname,
  FRIEND_LIST: APP_ROUTES.FRIEND_LIST.pathname,
  PROFILE_CHILD: APP_ROUTES.PROFILE_CHILD.pathname
}

export interface IFcmNotificationProps
  extends PropsFromRedux,
    IWithRouterProps {}

const FcmNotification = (props: IFcmNotificationProps) => {
  const isLoggedIn = useSelector(getIsLoggedInSelector)
  const userDeviceId = useSelector(getUserDeviceIdSelector)

  if (!window.cordova) {
    return null
  }

  async function updateDeviceId(deviceId = '') {
    if (deviceId !== userDeviceId) {
      props.actions.updateDeviceId(deviceId)
    }
  }

  function getRedirectUrl(payload: any) {
    const pageType: T_NOTIF_PAGE_TYPE = payload.pageType
    const to = NOTIF_TYPE_URL_MAP[pageType] || ''
    const pageParams: string = payload.pageParams || ''

    let paramProps: Record<string, string> = {}
    try {
      paramProps = JSON.parse(pageParams)
    } catch (error) {
      paramProps = {}
    }

    let redirectUrl = to as string
    Object.keys(paramProps).forEach(key => {
      redirectUrl = redirectUrl.replaceAll(`:${key}`, paramProps[key] || '')
    })

    return redirectUrl || APP_ROUTES.DASHBOARD_CHILD.pathname
  }

  function redirectHandler(payload: any) {
    const redirectUrl = getRedirectUrl(payload)
    props.navigateTo(redirectUrl)
  }

  useEffect(() => {
    if (window.cordova) {
      const { FirebasePlugin } = window as any

      if (isLoggedIn) {
        const { FirebasePlugin } = window as any
        FirebasePlugin.hasPermission((hasPermission: boolean) => {
          if (hasPermission) {
            FirebasePlugin.getToken(updateDeviceId)
            FirebasePlugin.onTokenRefresh(updateDeviceId)

            FirebasePlugin.onMessageReceived((notification: any) => {
              if (notification.tap) {
                redirectHandler(notification)
              }
            })
          }
        })
      } else {
        FirebasePlugin.unregister()
      }
    }
  }, [isLoggedIn, userDeviceId])

  return null
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    updateDeviceId: (deviceId: string) =>
      dispatch(updateDeviceIdService(deviceId))
  }
})

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withRouter(FcmNotification))
