import { createSelector } from '@reduxjs/toolkit'

import { I_LIBRARY_STATE } from './Types'

export const SLICE_NAME = 'library'

const select = (state: any) => state[SLICE_NAME] as I_LIBRARY_STATE

export const getLibraryReducer = createSelector(select, reducer => reducer)

export const getLibraryOwnedBooksReducer = createSelector(
  select,
  reducer => reducer.myLibrary.books
)

export const getLibraryOwnedBookIdsReducer = createSelector(
  select,
  reducer => reducer.myLibrary.booksOwned
)

export const getLibraryIsSharedSelector = createSelector(
  select,
  reducer => reducer.myLibrary.isShared
)

export const getSharedLibraryReducer = createSelector(
  select,
  reducer => reducer.sharedLibrary
)
