// export type T_U

import {
  T_BADGE_TYPE,
  T_BADGE_TYPE_COMPETITION_MAP,
  T_BADGE_TYPE_LIKE_MAP,
  T_BADGE_TYPE_MONTHLY_MAP,
  T_BADGE_TYPE_READ_MAP,
  T_BADGE_TYPE_SEASONAL_MAP,
  T_BADGE_TYPE_STREAK_MAP
} from '~/src/Entities/Badge/Badge.Types'

export interface I_USER_BADGES_STATE {
  badgesOwned: number
  badgeGroupCount: { [key in T_BADGE_TYPE]: number }
  badgeGroups: {
    LIKE: T_BADGE_TYPE_LIKE_MAP
    STREAK: T_BADGE_TYPE_STREAK_MAP
    READ: T_BADGE_TYPE_READ_MAP
    MONTHLY: T_BADGE_TYPE_MONTHLY_MAP
    SEASONAL: T_BADGE_TYPE_SEASONAL_MAP
    COMPETITION: T_BADGE_TYPE_COMPETITION_MAP
  }
}
export const INITIAL_STATE: I_USER_BADGES_STATE = {
  badgesOwned: 0,
  badgeGroupCount: {
    LIKE: 0,
    STREAK: 0,
    READ: 0,
    MONTHLY: 0,
    SEASONAL: 0,
    COMPETITION: 0
  },
  badgeGroups: {
    LIKE: {},
    STREAK: {},
    READ: {},
    MONTHLY: {},
    SEASONAL: {},
    COMPETITION: {}
  }
}
