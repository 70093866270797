import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { T_GROUP_FILTERS, T_GROUP_SORTS } from './TYPES'

export const groupListServiceName = 'Group/list'
export const groupListActions = traceActionsCreator(groupListServiceName)

export const setGroupListSortBy = createAction<T_GROUP_SORTS>('Group/setSortBy')
export const setGroupListFilterBy =
  createAction<T_GROUP_FILTERS>('Group/setFilterBy')

export const createGroupServiceName = 'Group/createGroup'
export const createGroupTraceActions = traceActionsCreator(
  createGroupServiceName
)

export const findGroupByIdServiceName = 'Group/findGroupById'
export const findGroupByIdTraceActions = traceActionsCreator(
  findGroupByIdServiceName
)

export const archiveGroupServiceName = 'Group/archiveGroup'
export const archiveGroupTraceActions = traceActionsCreator(
  archiveGroupServiceName
)

export const addGroupMembersServiceName = 'Group/addGroupMembers'
export const addGroupMembersTraceActions = traceActionsCreator(
  addGroupMembersServiceName
)

export const updateGroupMembersServiceName = 'Group/updateGroupMembers'
export const updateGroupMembersTraceActions = traceActionsCreator(
  updateGroupMembersServiceName
)
