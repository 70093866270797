import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions
} from '../User/Actions'
import {
  addGroupMembersTraceActions,
  archiveGroupTraceActions,
  findGroupByIdTraceActions,
  groupListActions,
  setGroupListFilterBy,
  setGroupListSortBy,
  updateGroupMembersTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_GROUP_REDUCER, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      setGroupListFilterBy,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.filterBy = payload
      }
    )

    builder.addCase(
      setGroupListSortBy,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.sortBy = payload
      }
    )

    builder.addCase(
      groupListActions.success,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.groups = payload
      }
    )

    builder.addCase(
      findGroupByIdTraceActions.success,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.group = payload
      }
    )

    builder.addCase(
      archiveGroupTraceActions.success,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.group = payload
      }
    )

    builder.addCase(
      addGroupMembersTraceActions.success,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.group = payload
      }
    )

    builder.addCase(
      updateGroupMembersTraceActions.success,
      (state: I_GROUP_REDUCER, { payload }) => {
        state.group = payload
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(getMeTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
