import { createSelector } from '@reduxjs/toolkit'

import { BOOK_FORM_BOOK_INFO_PROPS, I_BOOK_FORM_STATE } from './TYPES'

export const SLICE_NAME = 'bookForm'

const select = (state: any) => state[SLICE_NAME] as I_BOOK_FORM_STATE
const selectBookFormQuestions = (state: any) => state[SLICE_NAME].questions
const selectBookFormId = (state: any) => state[SLICE_NAME].id

export const getBookFormReducer = createSelector(
  select,
  reducer => reducer || {}
)

export const getBookFormBookInfoSelector = createSelector(
  select,
  selectBookInfo
)

export const getBookFormQuestionsSelector = createSelector(
  selectBookFormQuestions,
  questions => questions || {}
)

export const getBookFormIdSelector = createSelector(
  selectBookFormId,
  id => id || ''
)

function selectBookInfo(bookForm: I_BOOK_FORM_STATE) {
  return BOOK_FORM_BOOK_INFO_PROPS.reduce((acc, prop) => {
    acc[prop] = bookForm[prop]
    return acc
  }, {} as any)
}
