import { I_BOOK } from '../Book/TYPES'

import { T_COVER_IMAGE_IDS } from '~/src/Constants/COMPETITION_COVER'
import { I_USER_TYPE_VALUES } from '~/src/Constants/USER'

export type T_COMPETITION_FILTERS = 'ALL' | 'ONGOING' | 'UPCOMING' | 'COMPLETED'
export type T_COMPETITION_SORTS =
  | 'ALPHABETICALLY'
  | 'ALPHABETICALLY_REVERSE'
  | 'CREATED_AT'
  | 'CREATED_AT_REVERSE'
  | 'MEMBERS_COUNT'
  | 'MEMBERS_COUNT_REVERSE'
export type T_INVITE_STATUS = 'INVITED' | 'ACCEPTED' | 'REJECTED'
export type T_COMPETITION_STATUS = 'ONGOING' | 'UPCOMING' | 'COMPLETED'
export type T_COMPETITION_TYPE = 'PUBLIC' | 'PRIVATE'

export interface I_COMPETITION_MEMBERS {
  id: string
  fullName: string
  emailId: string
  avatar: string
  username: string
  dob: string
  age: number
  status: string
}
export interface I_COMPETITION_LEADERBORAD_USER {
  id: string
  userId: string
  competitionId: string
  pointsEarned: number
  booksRead: number
  correctAnswers: number
  incorrectAnswers: number
  createdAt: string
  updatedAt: string
  rank: number
  user: {
    avatar: string
    dob: string
    fullName: string
    emailId: string
    userType: I_USER_TYPE_VALUES
    id: string
    username: string
  }
}

export interface I_COMPETITION_BOOKS {
  id: string
  bookName: string
  coverImage: string
  minAge: number
  isRead: boolean
}

export interface I_COMPETITION {
  id: string
  competitionType: T_COMPETITION_TYPE
  competitionName: string
  competitionDescription: string
  remarks: string
  minAge: number
  maxAge: number
  anonymous: boolean
  status?: T_COMPETITION_STATUS

  startDate: string
  endDate: string

  members: I_COMPETITION_MEMBERS[]
  participantCount?: number
  bookIds?: string[]
  books: I_COMPETITION_BOOKS[] | I_BOOK[]
  earnablePoints?: number

  createdAt: string
  createdBy: string

  coverId: T_COVER_IMAGE_IDS
  coverImage: string

  inviteId?: string
  inviteValidity?: number

  startsIn?: number
  endsIn?: number
  totalDuration?: number
  hasJoined?: boolean
}

export type I_GROUPED_COMPETITION = {
  [key in T_COMPETITION_STATUS]: I_COMPETITION[]
}

export interface I_COMPETITION_BOOK_ANALYTICS {
  bookId: string
  bookName: string
  readByCount: number
  accuracy: number
}

export interface I_COMPETITION_ANALYTICS {
  mostAccurateBook?: I_COMPETITION_BOOK_ANALYTICS
  leastAccurateBook?: I_COMPETITION_BOOK_ANALYTICS
  allBooksReadUserCount: number
}

export interface I_COMPETITION_REDUCER {
  competitions: I_GROUPED_COMPETITION
  filterBy: T_COMPETITION_FILTERS
  sortBy: T_COMPETITION_SORTS
  selectedCompetition: I_COMPETITION | undefined
  selectedCompetitionAnalytics: I_COMPETITION_ANALYTICS
  invites: I_COMPETITION[]
  leaderboard: I_COMPETITION_LEADERBORAD_USER[]
}

export const INITIAL_STATE: I_COMPETITION_REDUCER = {
  competitions: {
    ONGOING: [],
    UPCOMING: [],
    COMPLETED: []
  },
  filterBy: 'ALL',
  sortBy: 'ALPHABETICALLY',
  selectedCompetition: undefined,
  selectedCompetitionAnalytics: {
    allBooksReadUserCount: 0
  },
  invites: [],
  leaderboard: []
}
