const FR_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/fr.png?quality=100',
      import.meta.url
    ).href,
    alt: 'FR',
    as: 'image/png'
  }
]

export default FR_FLAG_IMAGE
