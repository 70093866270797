import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  HOME: {
    pathname: ''
  } as AppRouteItem,
  LOGIN: {
    pathname: '/auth/login'
  } as AppRouteItem,
  REGISTER: {
    pathname: '/auth/register'
  } as AppRouteItem,
  FORGOT_USERNAME_IDENTIFIER_EMAIL: {
    pathname: '/auth/forgot-username/identifier/email'
  } as AppRouteItem,
  FORGOT_USERNAME_LIST: {
    pathname: '/auth/forgot-username/retrive/list'
  } as AppRouteItem,
  FORGOT_PASSWORD_IDENTIFIER_USERNAME: {
    pathname: '/auth/forgot-password/identifier/username'
  } as AppRouteItem,
  FORGOT_PASSWORD_SET: {
    pathname: '/auth/forgot-password/set'
  } as AppRouteItem,

  DASHBOARD_ADMIN: {
    pathname: '/admin-dashboard'
  } as AppRouteItem,
  DASHBOARD_ADULT: {
    pathname: '/adult-dashboard'
  } as AppRouteItem,
  DASHBOARD_CHILD: {
    pathname: '/child-dashboard'
  } as AppRouteItem,

  BOOK_LIST: {
    pathname: '/books'
  } as AppRouteItem,
  BOOK_FORM_BOOK_INFO: {
    pathname: '/books/:id/book-info'
  } as AppRouteItem,
  BOOK_FORM_QUESTIONS: {
    pathname: '/books/:id/questions'
  } as AppRouteItem,
  BOOK_FORM_REVIEW: {
    pathname: '/books/:id/review'
  } as AppRouteItem,
  BOOK_UPLOAD_GRATIFICATION: {
    pathname: '/books/gratification'
  } as AppRouteItem,
  BOOK_SHOW: {
    pathname: '/books/:id'
  } as AppRouteItem,
  BOOK_WORLD: {
    pathname: '/adventure'
  } as AppRouteItem,

  BOOK_READ: {
    pathname: '/competitions/:competitionId/books/:bookId/read'
  } as AppRouteItem,

  BOOK_READ_OUTCOME: {
    pathname: '/competitions/:competitionId/books/:bookId/read-outcome'
  } as AppRouteItem,

  COMPETITION_LIST: {
    pathname: '/competitions'
  } as AppRouteItem,
  COMPETITION_NEW: {
    pathname: '/competitions/new'
  } as AppRouteItem,
  COMPETITION_REVIEW: {
    pathname: '/competitions/review'
  } as AppRouteItem,
  COMPETITION_SHOW: {
    pathname: '/competitions/:id'
  } as AppRouteItem,
  COMPETITION_INVITE_LIST: {
    pathname: '/competition-invites'
  } as AppRouteItem,
  COMPETITION_LIST_CHILD: {
    pathname: '/competitions-child'
  } as AppRouteItem,
  COMPETITION_LIST_COMPLETED_CHILD: {
    pathname: '/competitions-completed-child'
  } as AppRouteItem,
  COMPETITION_SHOW_CHILD: {
    pathname: '/competitions-child/:id'
  } as AppRouteItem,

  EVENT_LIST: {
    pathname: '/events'
  } as AppRouteItem,
  EVENT_NEW: {
    pathname: '/events/new'
  } as AppRouteItem,
  EVENT_REVIEW: {
    pathname: '/events/review'
  } as AppRouteItem,
  EVENT_LIST_CHILD: {
    pathname: '/events-child'
  } as AppRouteItem,
  EVENT_LIST_COMPLETED_CHILD: {
    pathname: '/events-completed-child'
  } as AppRouteItem,

  GROUP_LIST: {
    pathname: '/groups'
  } as AppRouteItem,
  GROUP_NEW: {
    pathname: '/groups/new'
  } as AppRouteItem,
  GROUP_SHOW: {
    pathname: '/groups/:id'
  } as AppRouteItem,

  PROFILE: {
    pathname: '/profile'
  } as AppRouteItem,
  PROFILE_CHILD: {
    pathname: '/profile-child'
  } as AppRouteItem,

  USER_LIST: {
    pathname: '/users'
  } as AppRouteItem,
  USER_PROFILE: {
    pathname: '/users/:id'
  } as AppRouteItem,

  BOOK_LIBRARY: {
    pathname: '/my-library'
  } as AppRouteItem,

  FRIEND_LIST: {
    pathname: '/friends'
  } as AppRouteItem,
  FRIEND_ADD: {
    pathname: '/friends/add'
  } as AppRouteItem,

  ACHIEVEMENTS: {
    pathname: '/achievements'
  } as AppRouteItem,

  SUBSCRIPTION_LIST: {
    pathname: '/subscriptions'
  } as AppRouteItem,

  SUBSCRIPTION_MEMBER_MGMT: {
    pathname: '/subscriptions/member-mgmt'
  } as AppRouteItem,

  COUPON_LIST: {
    pathname: '/coupons'
  } as AppRouteItem,

  COUPON_NEW: {
    pathname: '/coupons/new'
  } as AppRouteItem,

  COUPON_EDIT: {
    pathname: '/coupons/:id'
  } as AppRouteItem,

  SUBSCRIPTION_HISTORY_LIST: {
    pathname: '/subscription-history-list'
  } as AppRouteItem,

  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export default APP_ROUTES
