import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  bookFeedbackTraceActions,
  bookMarkReadTraceActions
} from '../BookRead/Actions'
import {
  deleteAccountTraceActions,
  getUserBadgesTraceActions,
  registerUserActions
} from '../User/Actions'
import { SLICE_NAME } from './Selectors'
import { I_USER_BADGES_STATE, INITIAL_STATE } from './TYPES'

import { _groupBy, _keyBy } from '~/src/Utils/lodash'

import { T_BADGE } from '~/src/Entities/Badge/Badge.Types'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      bookMarkReadTraceActions.success,
      (state: I_USER_BADGES_STATE, { payload }) => {
        const { userBadges } = payload
        return _buildBadgeReducer(userBadges)
      }
    )
    builder.addCase(
      bookFeedbackTraceActions.success,
      (state: I_USER_BADGES_STATE, { payload }) => {
        const { userBadges } = payload
        return _buildBadgeReducer(userBadges)
      }
    )

    builder.addCase(
      loginTraceActions.success,
      (state: I_USER_BADGES_STATE, { payload }) => {
        const { userBadges } = payload
        return _buildBadgeReducer(userBadges)
      }
    )

    builder.addCase(
      getUserBadgesTraceActions.success,
      (state: I_USER_BADGES_STATE, { payload }) => {
        return _buildBadgeReducer(payload)
      }
    )

    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer

function _buildBadgeReducer(userBadges: T_BADGE[] = []): I_USER_BADGES_STATE {
  const badgesOwned = userBadges.length
  const badgeGroupTypeMap = _groupBy(userBadges, 'badgeType')

  return {
    badgesOwned,
    badgeGroupCount: {
      LIKE: (badgeGroupTypeMap.LIKE || []).length,
      STREAK: (badgeGroupTypeMap.STREAK || []).length,
      READ: (badgeGroupTypeMap.READ || []).length,
      MONTHLY: (badgeGroupTypeMap.MONTHLY || []).length,
      SEASONAL: (badgeGroupTypeMap.SEASONAL || []).length,
      COMPETITION: (badgeGroupTypeMap.COMPETITION || []).length
    },
    badgeGroups: {
      LIKE: _keyBy(badgeGroupTypeMap.LIKE || [], 'badgeName'),
      STREAK: _keyBy(badgeGroupTypeMap.STREAK || [], 'badgeName'),
      READ: _keyBy(badgeGroupTypeMap.READ || [], 'badgeName'),
      MONTHLY: _keyBy(badgeGroupTypeMap.MONTHLY || [], 'badgeName'),
      SEASONAL: _keyBy(badgeGroupTypeMap.SEASONAL || [], 'badgeName'),
      COMPETITION: _keyBy(badgeGroupTypeMap.COMPETITION || [], 'badgeName')
    }
  }
}
