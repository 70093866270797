import { T_COUPON } from '~/src/Entities/Coupon/Coupon.Types'

export interface I_COUPON_REDUCER {
  list: T_COUPON[]
  selected: T_COUPON
}

export const INITIAL_STATE: I_COUPON_REDUCER = {
  list: [],
  selected: {
    id: '',
    couponType: 'PERCENTAGE',
    couponValue: 0,
    couponCode: '',
    isEnabled: false
  }
}
