import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'bookRead'

const select = (state: any) => state[SLICE_NAME]

export const getBookReadReducer = createSelector(
  select,
  (reducer: any) => reducer
)

export const getBookReadFormSelector = createSelector(
  select,
  (reducer: any) => reducer.readForm
)

export const getBookForReadingSelector = createSelector(
  select,
  (reducer: any) => reducer.bookForReading
)
