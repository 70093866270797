import { createAction } from '@reduxjs/toolkit'

export const setAudioClickVolumeAction = createAction<number>(
  'Settings/setAudioClickVolume'
)

export const setAudioClickMutedAction = createAction<boolean>(
  'Settings/setAudioClickMuted'
)

export const setAudioBackgroundVolumeAction = createAction<number>(
  'Settings/setAudioBackgroundVolume'
)

export const setAudioBackgroundMutedAction = createAction<boolean>(
  'Settings/setAudioBackgroundMuted'
)
