export interface I_BOOK_FORM_STATE {
  id: string
  bookName: string
  genre: string
  isSeries: boolean
  seriesNo?: number
  seriesName?: string
  language: string
  authors: string[]
  publisher: string
  noOfPages?: number
  readTimeInMins?: number
  coverImage: string
  coverImageMimeType: string
  minAge?: number
  tags: string[]
  questions: Array<{
    question: string
    answer: string
    option1: string
    option2: string
  }>
}

export const INITIAL_STATE: I_BOOK_FORM_STATE = {
  id: '',
  bookName: '',
  genre: '',
  isSeries: false,
  seriesNo: undefined,
  seriesName: undefined,
  language: '',
  authors: [],
  publisher: '',
  minAge: undefined,
  noOfPages: undefined,
  readTimeInMins: undefined,
  coverImage: '',
  coverImageMimeType: '',
  tags: [],
  questions: [
    {
      question: '',
      answer: '',
      option1: '',
      option2: ''
    }
  ]
}

export const BOOK_FORM_BOOK_INFO_PROPS = [
  'bookName',
  'genre',
  'isSeries',
  'seriesNo',
  'seriesName',
  'language',
  'authors',
  'publisher',
  'noOfPages',
  'readTimeInMins',
  'coverImage',
  'coverImageMimeType',
  'minAge',
  'tags'
] as const

export type T_BOOK_FORM_BOOK_INFO_PROPS =
  (typeof BOOK_FORM_BOOK_INFO_PROPS)[number]
