import { T_USER_LEVEL } from '~/src/Constants/LEVELS'
import { userLevelCalculator } from '~/src/Utils/userLevelCalculator'

export interface I_USER_BANK_STATE {
  id: string
  pointsEarned: number
  pointsRedemeed: number
  booksRead: number
  booksReadWithMinPoints: number
  booksLiked: number
  perfectReadStreak: number
  userLevel: T_USER_LEVEL
}

export const INITIAL_STATE: I_USER_BANK_STATE = {
  id: '',
  pointsEarned: 0,
  pointsRedemeed: 0,
  booksRead: 0,
  booksReadWithMinPoints: 0,
  booksLiked: 0,
  perfectReadStreak: 0,
  userLevel: userLevelCalculator(0)
}
