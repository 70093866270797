import { getIsLoggedInSelector } from '~/src/Redux/Auth/Selectors'
import { getUserUserTypeSelector } from '~/src/Redux/User/Selectors'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { USER_TYPE_CONSTANTS } from '~/src/Constants/USER'
import AppStore from '~/src/Configurations/AppStore'

export default function adultAuthLoader() {
  const state = AppStore.getState()
  const isLoggedIn = getIsLoggedInSelector(state)
  const userType = getUserUserTypeSelector(state)

  if (!isLoggedIn) {
    throw new Response(APP_ROUTES.LOGIN.pathname as string, { status: 401 })
  }

  if (userType === USER_TYPE_CONSTANTS.CHILD) {
    throw new Response(APP_ROUTES.DASHBOARD_CHILD.pathname as string, {
      status: 403
    })
  }

  if (userType === USER_TYPE_CONSTANTS.ADMIN) {
    throw new Response(APP_ROUTES.DASHBOARD_ADMIN.pathname as string, {
      status: 403
    })
  }

  return {}
}
