import { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAudioSettingsSelector } from '../Redux/Settings/Selectors'

import BACKGROUND_SOUND from '../Constants/ASSETS/AUDIOS/BACKGROUND_SOUND'

interface IDocumentEventListenerProps {}

const DocumentEventListener: FunctionComponent<
  IDocumentEventListenerProps
> = () => {
  const audioSettings = useSelector(getAudioSettingsSelector)
  const { background } = audioSettings

  function onPause() {
    if (!background.muted) {
      BACKGROUND_SOUND.pause()
    }
  }

  function onResume() {
    if (!background.muted) {
      BACKGROUND_SOUND.play()
    }
  }

  function visibilitychange() {
    if (document.hidden) {
      onPause()
    } else {
      onResume()
    }
  }

  useEffect(() => {
    if (window.cordova) {
      document.addEventListener('pause', onPause, false)
      document.addEventListener('resume', onResume, false)
    } else {
      document.addEventListener('visibilitychange', visibilitychange, false)
    }

    return () => {
      if (window.cordova) {
        document.removeEventListener('pause', onPause, false)
        document.removeEventListener('resume', onResume, false)
      } else {
        document.removeEventListener(
          'visibilitychange',
          visibilitychange,
          false
        )
      }
    }
  }, [background.muted])

  return null
}

export default DocumentEventListener
