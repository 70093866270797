import { createSelector } from '@reduxjs/toolkit'

import { T_SEARCH_REDUCER } from './TYPES'

export const SLICE_NAME = 'search'

const select = (state: any) => state[SLICE_NAME] as T_SEARCH_REDUCER

export const getSearchReducer = createSelector(select, reducer => reducer)

export const getSearchChildrenSelector = createSelector(
  select,
  reducer => reducer.children
)

export const getSearchBooksSelector = createSelector(
  select,
  reducer => reducer.books
)

export const getSearchGroupsSelector = createSelector(
  select,
  reducer => reducer.groups
)

export const getSearchHashtagsSelector = createSelector(
  select,
  reducer => reducer.hashtags
)

export const getSearchAuthorsSelector = createSelector(
  select,
  reducer => reducer.authors
)

export const getSearchPublishersSelector = createSelector(
  select,
  reducer => reducer.publishers
)

export const getSearchBookOwnersSelector = createSelector(
  select,
  reducer => reducer.bookOwners
)
