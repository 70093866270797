import React, { PureComponent } from 'react'
import { Outlet } from 'react-router-dom'
import { DsBox } from '@am92/react-design-system'

import Header from '../Components/Header'
import FcmNotification from '~/src/Components/Fcm/FcmNotification'

interface IAdultAuthenticatedLayoutProps {}

class AdultAuthenticatedLayout extends PureComponent<IAdultAuthenticatedLayoutProps> {
  render() {
    return (
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'var(--vh)', // TODO: Solve for web and cordova
          overflow: 'auto'
          // scrollbarWidth: 'none',
          // '&::-webkit-scrollbar': {
          //   display: 'none'
          // }
        }}
      >
        <FcmNotification />
        <Header />
        <Outlet />
      </DsBox>
    )
  }
}

export default AdultAuthenticatedLayout
