import { createAction } from '@reduxjs/toolkit'

export const resetUsersByEmailIdAction = createAction(
  'ForgotCredentials/resetUsersByEmailId'
)

export const setForgotPasswordShowOtpAction = createAction<boolean>(
  'ForgotCredentials/setForgotPasswordShowOtp'
)

export const setForgotPasswordShowOtpLimitPopupAction = createAction<boolean>(
  'ForgotCredentials/setForgotPasswordShowOtpLimitPopup'
)
