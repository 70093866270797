import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

import {
  I_SUBSCRIPTION,
  I_SUBSCRIPTION_TENURES
} from '~/src/Entities/Subscription/Subscription.Types'

// export const sampleAction = createAction('Subscription/sample')

export const fetchSubscriptionsServiceName = 'Subscription/fetchSubscriptions'
export const fetchSubscriptionsTraceActions = traceActionsCreator(
  fetchSubscriptionsServiceName
)

export type T_SET_SELECTED_SUBSCRIPTION = {
  selected: I_SUBSCRIPTION
  selectedTenure: I_SUBSCRIPTION_TENURES
}

export const setSelectedSubscription =
  createAction<T_SET_SELECTED_SUBSCRIPTION>('Subscription/setSelected')

export const setSelectedTenureSubscription =
  createAction<I_SUBSCRIPTION_TENURES>('Subscription/setSelectedTenure')

export const resetCouponSubscription = createAction('Subscription/resetCoupon')

export const createOrderServiceName = 'Subscription/createOrder'
export const createOrderTraceActions = traceActionsCreator(
  createOrderServiceName
)

export const captureOrderPaymentServiceName = 'Subscription/captureOrderPayment'
export const captureOrderPaymentTraceActions = traceActionsCreator(
  captureOrderPaymentServiceName
)

export const fetchOrderListServiceName = 'Subscription/fetchOrderList'
export const fetchOrderListTraceActions = traceActionsCreator(
  fetchOrderListServiceName
)
