import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { bookMarkReadTraceActions } from '../BookRead/Actions'
import { getProgressAnalyticsTraceActions } from '../Competition/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import { SLICE_NAME } from './Selectors'
import { I_ANALYTICS_STATE, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      bookMarkReadTraceActions.success,
      (state: I_ANALYTICS_STATE, { payload }) => {
        state.book = payload.bookAnalytics
      }
    )

    builder.addCase(
      getProgressAnalyticsTraceActions.success,
      (state: I_ANALYTICS_STATE, { payload }) => {
        state.competition = payload || INITIAL_STATE.competition
      }
    )

    builder.addCase(
      getProgressAnalyticsTraceActions.error,
      (state: I_ANALYTICS_STATE) => {
        state.competition = INITIAL_STATE.competition
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
