const MASCOT_BIRD_VERSION_5 =
  process.env.APP_MODE === 'CORDOVA'
    ? [
        {
          src: new URL(
            '~/public/assets/images/mascots3d/mascotBirdVersion5.png?as=webp',
            import.meta.url
          ).href,
          alt: 'Mascot',
          as: 'image/webp'
        }
      ]
    : [
        {
          src: new URL(
            '~/public/assets/images/mascots3d/mascotBirdVersion5.png?as=avif',
            import.meta.url
          ).href,
          alt: 'Mascot',
          as: 'image/avif'
        },
        {
          src: new URL(
            '~/public/assets/images/mascots3d/mascotBirdVersion5.png?as=webp',
            import.meta.url
          ).href,
          alt: 'Mascot',
          as: 'image/webp'
        },
        {
          src: new URL(
            '~/public/assets/images/mascots3d/mascotBirdVersion5.png',
            import.meta.url
          ).href,
          alt: 'Mascot',
          as: 'image/png'
        }
      ]

export default MASCOT_BIRD_VERSION_5
