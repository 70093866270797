import { createSelector } from '@reduxjs/toolkit'

import { I_ANALYTICS_STATE } from './TYPES'

export const SLICE_NAME = 'analytics'

const select = (state: any): I_ANALYTICS_STATE => state[SLICE_NAME]

export const getAnalyticsReducer = select

export const getAnalyticsBookSelector = createSelector(
  select,
  reducer => reducer.book
)
