const EN_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/en.png?quality=100',
      import.meta.url
    ).href,
    alt: 'EN',
    as: 'image/png'
  }
]

export default EN_FLAG_IMAGE
