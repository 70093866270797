import { T_GENERIC_LANGUAGE_MAP } from './LANGUAGE'

export type I_USER_TYPE_VALUES = 'CHILD' | 'ADULT' | 'ADMIN'

export type I_USER_TYPE_CONSTANTS = {
  [key in I_USER_TYPE_VALUES]: I_USER_TYPE_VALUES
}

export type T_SUBSCRIPTION_STATUS = 'ACTIVE' | 'EXPIRED'

export const USER_TYPE_CONSTANTS: I_USER_TYPE_CONSTANTS = {
  CHILD: 'CHILD',
  ADULT: 'ADULT',
  ADMIN: 'ADMIN'
}

export type T_USER_SUB_TYPE_VALUES = 'FREE' | 'PERIODIC' | 'LIFETIME'

export type T_USER_SUB_TYPE_CONSTANTS = {
  [key in T_USER_SUB_TYPE_VALUES]: T_USER_SUB_TYPE_VALUES
}

export const USER_SUB_TYPE_CONSTANTS: T_USER_SUB_TYPE_CONSTANTS = {
  FREE: 'FREE',
  PERIODIC: 'PERIODIC',
  LIFETIME: 'LIFETIME'
}

export const USER_SUB_TYPE_LABELS: T_GENERIC_LANGUAGE_MAP<{
  [key in T_USER_SUB_TYPE_VALUES]: string
}> = {
  de: {
    FREE: 'Kostenlose Testversion',
    PERIODIC: 'Zeitlich begrenzt',
    LIFETIME: 'Lebenslang'
  },
  en: {
    FREE: 'Free Trial',
    PERIODIC: 'Temporary',
    LIFETIME: 'Lifetime'
  },
  fr: {
    FREE: 'Essai gratuit',
    PERIODIC: 'Temporaire',
    LIFETIME: 'À vie'
  },
  hu: {
    FREE: 'Ingyenes próbaidő',
    PERIODIC: 'Időszakos',
    LIFETIME: 'Élethosszig tartó'
  }
}

export const USER_SUB_STATUS_LABELS: T_GENERIC_LANGUAGE_MAP<{
  [key in T_SUBSCRIPTION_STATUS]: string
}> = {
  de: {
    ACTIVE: 'Aktiv',
    EXPIRED: 'Abgelaufen'
  },
  en: {
    ACTIVE: 'Active',
    EXPIRED: 'Expired'
  },
  fr: {
    ACTIVE: 'Actif',
    EXPIRED: 'Expiré'
  },
  hu: {
    ACTIVE: 'Aktiv',
    EXPIRED: 'Lejárt'
  }
}
