export type T_SEARCH_REDUCER = {
  children: any[]
  books: any[]
  groups: any[]
  hashtags: any[]
  authors: any[]
  publishers: any[]
  bookOwners: any[]
}

export const INITIAL_STATE: T_SEARCH_REDUCER = {
  children: [],
  books: [],
  groups: [],
  hashtags: [],
  authors: [],
  publishers: [],
  bookOwners: []
}
