import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  competitionAddMembersActions,
  competitionAnalyticsActions,
  competitionFindByIdActions,
  competitionListActions,
  competitionMyInvitesTraceActions,
  competitionRemoveMembersActions,
  competitionResendInviteActions,
  getCompetitionLeaderboardTraceActions,
  setCompetitionListFilterBy,
  setCompetitionListSortBy,
  updateCompetitionByIdTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_COMPETITION_REDUCER, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<I_COMPETITION_REDUCER>
  ): void => {
    builder.addCase(setCompetitionListFilterBy, (state, { payload }) => {
      state.filterBy = payload
    })

    builder.addCase(setCompetitionListSortBy, (state, { payload }) => {
      state.sortBy = payload
    })

    builder.addCase(competitionListActions.success, (state, { payload }) => {
      state.competitions = payload
    })

    builder.addCase(
      competitionFindByIdActions.success,
      (state, { payload }) => {
        state.selectedCompetition = payload
      }
    )

    builder.addCase(
      updateCompetitionByIdTraceActions.success,
      (state, { payload }) => {
        state.selectedCompetition = payload
      }
    )

    builder.addCase(
      competitionResendInviteActions.success,
      (state, { payload }) => {
        state.selectedCompetition = payload
      }
    )
    builder.addCase(
      competitionRemoveMembersActions.success,
      (state, { payload }) => {
        state.selectedCompetition = payload
      }
    )
    builder.addCase(
      competitionAddMembersActions.success,
      (state, { payload }) => {
        state.selectedCompetition = payload
      }
    )

    builder.addCase(
      competitionMyInvitesTraceActions.success,
      (state, { payload }) => {
        state.invites = payload
      }
    )

    builder.addCase(
      competitionAnalyticsActions.success,
      (state, { payload }) => {
        state.selectedCompetitionAnalytics = payload
      }
    )

    builder.addCase(getCompetitionLeaderboardTraceActions.loading, state => {
      state.leaderboard = INITIAL_STATE.leaderboard
    })

    builder.addCase(
      getCompetitionLeaderboardTraceActions.success,
      (state, { payload }) => {
        state.leaderboard = payload
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
