import { WebHttpRequestOptions } from '@am92/web-http'

import { getUserBankServiceName, getUserBankTraceActions } from '../Actions'

import serviceActionCreatorWithTokenRotation from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import { asHttp } from '~/src/Configurations/WebHttp'

async function getUserBank() {
  const options: WebHttpRequestOptions = {
    url: '/users/get-user-bank',
    method: 'GET'
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getUserBankService = serviceActionCreatorWithTokenRotation(
  getUserBankTraceActions,
  getUserBank
)

export default getUserBankService
export { getUserBankServiceName }
