import { T_LIBRARY } from '~/src/Entities/Library/Library.Types'

export interface I_LIBRARY_STATE {
  myLibrary: T_LIBRARY
  sharedLibrary: T_LIBRARY
}

export const INITIAL_STATE: I_LIBRARY_STATE = {
  myLibrary: {
    id: '',
    booksOwned: [],
    books: [],
    isShared: false
  },
  sharedLibrary: {
    id: '',
    booksOwned: [],
    books: [],
    isShared: false
  }
}
