import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { validateCouponTraceActions } from '../Coupon/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  captureOrderPaymentTraceActions,
  createOrderTraceActions,
  fetchOrderListTraceActions,
  fetchSubscriptionsTraceActions,
  resetCouponSubscription,
  setSelectedSubscription,
  setSelectedTenureSubscription
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      fetchSubscriptionsTraceActions.success,
      (state, { payload }) => {
        state.subscriptions = payload
      }
    )

    builder.addCase(setSelectedSubscription, (state, { payload }) => {
      state.selected = payload.selected
      state.selectedTenure = payload.selectedTenure
    })

    builder.addCase(setSelectedTenureSubscription, (state, { payload }) => {
      state.selectedTenure = payload
    })

    builder.addCase(
      validateCouponTraceActions.success,
      (state, { payload }) => {
        state.coupon = payload
      }
    )

    builder.addCase(validateCouponTraceActions.error, state => {
      state.coupon = undefined
    })

    builder.addCase(resetCouponSubscription, state => {
      state.coupon = undefined
    })

    builder.addCase(createOrderTraceActions.success, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(
      captureOrderPaymentTraceActions.success,
      (state, { payload }) => {
        state.order = payload
      }
    )

    builder.addCase(
      fetchOrderListTraceActions.success,
      (state, { payload }) => {
        state.orderHistory = payload
      }
    )

    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
