import traceActionsCreator from '../traceActionsCreator'

// export const sampleAction = createAction('Library/sample')

export const getMyLibraryServiceName = 'Library/getMyLibrary'
export const getMyLibraryTraceActions = traceActionsCreator(
  getMyLibraryServiceName
)

export const updateMyLibraryServiceName = 'Library/updateMyLibrary'
export const updateMyLibraryTraceActions = traceActionsCreator(
  updateMyLibraryServiceName
)

export const addBookToLibraryServiceName = 'Library/addBookToLibrary'
export const addBookToLibraryTraceActions = traceActionsCreator(
  addBookToLibraryServiceName
)

export const removeBookFromLibraryServiceName = 'Library/removeBookFromLibrary'
export const removeBookFromLibraryTraceActions = traceActionsCreator(
  removeBookFromLibraryServiceName
)

export const getSharedLibraryServiceName = 'Library/getSharedLibrary'
export const getSharedLibraryTraceActions = traceActionsCreator(
  getSharedLibraryServiceName
)
