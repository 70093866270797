import { useEffect } from 'react'

export interface IFcmPermissionProps {}

const FcmPermission = () => {
  if (!window.cordova) {
    return null
  }

  async function requestFcmPermission() {
    if (window.cordova) {
      const { FirebasePlugin } = window as any
      FirebasePlugin.hasPermission((hasPermission: boolean) => {
        if (!hasPermission) {
          FirebasePlugin.grantPermission()
        }
      })
    }
  }

  useEffect(() => {
    if (window.cordova) {
      requestFcmPermission()
    }
  }, [])

  return null
}

export default FcmPermission
