import { I_BOOK } from '../Book/TYPES'
import { T_COMPETITION_STATUS } from '../Competition/TYPES'

import { T_COVER_IMAGE_IDS } from '~/src/Constants/COMPETITION_COVER'

export interface I_BOOK_READ_RESULT {
  question: string
  response: string
  isCorrect: boolean
}

export interface I_BOOK_READ_FORM {
  resultMap: { [key: number]: I_BOOK_READ_RESULT | undefined }
  activeStep: number
  swipeDirection: 'x' | 'x-reverse'
}

export interface I_APPEND_RESULT_ACTION {
  index: number
  result: I_BOOK_READ_RESULT
}

export interface I_BOOK_FOR_READING {
  bookId: string
  bookName: string
  coverImage: string
  questions: I_BOOK['questions']
  competition?: {
    id: string
    competitionType: string
    competitionName: string
    competitionDescription: string
    status: T_COMPETITION_STATUS
    coverId: T_COVER_IMAGE_IDS
  }
}

export interface I_BOOK_READ_REDUCER {
  readForm: I_BOOK_READ_FORM
  bookForReading: I_BOOK_FOR_READING
}

export const INITIAL_STATE: I_BOOK_READ_REDUCER = {
  bookForReading: {
    bookId: '',
    bookName: '',
    coverImage: '',
    questions: []
  },
  readForm: {
    resultMap: {},
    activeStep: 0,
    swipeDirection: 'x'
  }
}
