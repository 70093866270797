import { createSelector } from '@reduxjs/toolkit'

import { I_FUN_FACT_STATE } from './TYPES'

export const SLICE_NAME = 'funFact'

const select = (state: any) => state[SLICE_NAME] as I_FUN_FACT_STATE

export const getFunFactReducer = select

export const getRandomFunFact = createSelector(
  select,
  reducer => reducer.randomFunFact
)
