import { createAction } from '@reduxjs/toolkit'

import { T_PLATFORM_USER_FILTERS, T_PLATFORM_USER_SORTS } from './TYPES'

// export const sampleAction = createAction('PlatformUsers/sample')

export const setPlatformUsersSortBy = createAction<T_PLATFORM_USER_SORTS>(
  'PlatformUsers/setSortBy'
)
export const setPlatformUsersFilterBy = createAction<T_PLATFORM_USER_FILTERS>(
  'PlatformUsers/setFilterBy'
)
