import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import {
  DsAvatar,
  DsBox,
  DsIconButton,
  DsImage,
  DsMenu,
  DsMenuItem,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { differenceInMinutes } from 'date-fns'

import withPageConstants, {
  IWithPageConstantsProps
} from '~/src/Hocs/withPageConstants'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import LoaderFullPage from '~/src/Components/LoaderFullPage'

import HEADER_PAGE_CONSTANTS from './Header.PageConstants'
import { T_HEADER_PAGE_CONSTANTS } from './Header.PageConstants/TYPES'

import { getLoggedInAtSelector } from '~/src/Redux/Auth/Selectors'
import loginWithRefreshTokenService from '~/src/Redux/Auth/Services/loginWithRefreshToken.Service'
import logoutService, {
  logoutServiceName
} from '~/src/Redux/Auth/Services/logout.Service'
import { isServiceLoading } from '~/src/Redux/ServiceTracker/Selectors'
import {
  getUserFullNameSelector,
  getUserUserTypeSelector
} from '~/src/Redux/User/Selectors'
import updateDeviceIdService, {
  updateDeviceIdServiceName
} from '~/src/Redux/User/Services/updateDeviceId.Service'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import LOGO_IMAGE from '~/src/Constants/ASSETS/MISC/LOGO_IMAGE'

interface IHeaderProps
  extends IWithPageConstantsProps<T_HEADER_PAGE_CONSTANTS>,
    IWithRouterProps,
    PropsFromRedux {}

interface IHeaderState {
  menuOpen: boolean
}

const DEFAULT_STATE: IHeaderState = {
  menuOpen: false
}

export class Header extends Component<IHeaderProps, IHeaderState> {
  state = DEFAULT_STATE
  menuRef: HTMLDivElement | null

  constructor(props: IHeaderProps) {
    super(props)
    this.menuRef = null
  }

  componentDidMount(): void {
    const { loggedInAt } = this.props
    const loginDiff = differenceInMinutes(new Date(loggedInAt), new Date())

    if (isNaN(loginDiff) || loginDiff < -12) {
      this.handleRelogin()
    }
  }

  handleRelogin = async () => {
    const { actions } = this.props
    const response = await actions.loginWithRefreshTokenService()

    if (
      response?._isWebHttpError &&
      (response.errorCode === 'User::TOKEN_EXPIRED' ||
        response.errorCode === 'User::INVALID_REFRESH_TOKEN')
    ) {
      if (window.cordova) {
        await actions.updateDeviceIdService('')
      }
      actions.logoutService()
    }
  }

  handleMenuOpen = () => this.setState({ menuOpen: true })

  handleMenuClose = () => this.setState({ menuOpen: false })

  handleProfileMenuClick = () => {
    this.props.navigateTo(APP_ROUTES.PROFILE.pathname)
    this.handleMenuClose()
  }

  handleLogoutMenuClick = async () => {
    const { actions } = this.props

    if (window.cordova) {
      await actions.updateDeviceIdService('')
    }
    actions.logoutService()
    this.handleMenuClose()
  }

  handleGoToDashboard = () => {
    const { userType } = this.props
    if (userType === 'CHILD') {
      return this.props.navigateTo(APP_ROUTES.DASHBOARD_CHILD.pathname)
    }

    if (userType === 'ADULT') {
      return this.props.navigateTo(APP_ROUTES.DASHBOARD_ADULT.pathname)
    }

    if (userType === 'ADMIN') {
      return this.props.navigateTo(APP_ROUTES.DASHBOARD_ADMIN.pathname)
    }
  }

  render() {
    const { PAGE_CONSTANTS, name, initials, isSubmitting } = this.props
    const { menuOpen } = this.state

    return (
      <>
        {isSubmitting && <LoaderFullPage />}
        <DsBox
          sx={theme => ({
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              lg: 'var(--ds-spacing-pleasant)'
            },
            py: 'var(--ds-spacing-cool)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.appBar,
            borderBottom: '1px solid var(--ds-colour-strokeDefault)'
          })}
        >
          <DsStack
            direction='row'
            spacing='var(--ds-spacing-glacial)'
            onClick={this.handleGoToDashboard}
            sx={{ alignItems: 'center', cursor: 'pointer' }}
          >
            <DsImage
              aspectRatio={1 / 1}
              srcSet={LOGO_IMAGE}
              WrapperProps={{ sx: { height: '48px', width: '48px' } }}
              style={{ display: 'block' }}
            />

            <DsTypography
              component='div'
              variant='headingBoldLarge'
              sx={{
                fontWeight: 'var(--ds-typo-fontWeightLight)',
                display: { xs: 'none', lg: 'block' }
              }}
            >
              GriffyReads
            </DsTypography>
          </DsStack>

          <DsStack direction='row' spacing='var(--ds-spacing-frostbite)'>
            <DsIconButton
              sx={{
                p: 'var(--ds-spacing-frostbite)',
                border: '1px solid var(--ds-colour-strokeDefault)'
              }}
            >
              <DsRemixIcon className='ri-notification-line' />
            </DsIconButton>
            <DsStack
              ref={ref => (this.menuRef = ref)}
              direction='row'
              spacing='var(--ds-spacing-quickFreeze)'
              sx={{
                p: 'var(--ds-spacing-frostbite)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: '88px',
                alignItems: 'center'
              }}
            >
              <DsAvatar
                ds-variant='text'
                ds-size='S'
                sx={{
                  border: 'none',
                  backgroundColor: 'var(--ds-colour-neutral3)'
                }}
              >
                {initials}
              </DsAvatar>
              <DsTypography variant='bodyRegularSmall'>{name}</DsTypography>
              <DsIconButton onClick={this.handleMenuOpen}>
                <DsRemixIcon className='ri-arrow-down-s-fill' />
              </DsIconButton>
              <DsMenu
                open={menuOpen}
                onClose={this.handleMenuClose}
                anchorEl={this.menuRef}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                PaperProps={{
                  sx: {
                    minWidth: '256px'
                  }
                }}
              >
                <DsMenuItem onClick={this.handleProfileMenuClick}>
                  <DsRemixIcon
                    className='ri-user-3-line'
                    sx={{ mr: 'var(--ds-spacing-glacial)' }}
                  />
                  {PAGE_CONSTANTS.PROFILE_TEXT}
                </DsMenuItem>
                <DsMenuItem
                  onClick={this.handleLogoutMenuClick}
                  sx={{ color: 'var(--ds-colour-supportNegative)' }}
                >
                  <DsRemixIcon
                    className='ri-logout-circle-r-line'
                    sx={{ mr: 'var(--ds-spacing-glacial)' }}
                  />
                  {PAGE_CONSTANTS.LOGOUT_TEXT}
                </DsMenuItem>
              </DsMenu>
            </DsStack>
          </DsStack>
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const fullName = getUserFullNameSelector(state) || ''
  const name = fullName.split(' ').shift() || ''
  const userType = getUserUserTypeSelector(state)
  const initials = fullName
    .split(' ')
    .map((part: string) => part[0])
    .join('')
    .substring(0, 2)
    .toUpperCase()

  const isSubmitting = isServiceLoading(state, [
    logoutServiceName,
    updateDeviceIdServiceName
  ])
  const loggedInAt = getLoggedInAtSelector(state)

  return { name, initials, userType, isSubmitting, loggedInAt }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    logoutService: () => dispatch(logoutService()),
    loginWithRefreshTokenService: () =>
      dispatch(loginWithRefreshTokenService()),
    updateDeviceIdService: (deviceId: string) =>
      dispatch(updateDeviceIdService(deviceId))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default withPageConstants(
  HEADER_PAGE_CONSTANTS,
  connector(withRouter(Header))
)
