import { createSelector } from '@reduxjs/toolkit'

import { I_BOOK_REDUCER } from './TYPES'

import { _keys, _sortBy } from '~/src/Utils/lodash'

export const SLICE_NAME = 'book'

const select = (state: any) => state[SLICE_NAME] as I_BOOK_REDUCER

export const getBookListSelector = createSelector(select, reducer => {
  const genres = _sortBy(_keys(reducer.books))
  return { genres, booksByGeners: reducer.books }
})

export const getBookSelector = createSelector(select, reducer => reducer.book)

export const getBookSearchPropsSelector = createSelector(
  select,
  reducer => reducer.searchProps
)

export const getBookRecentReadSelector = createSelector(
  select,
  reducer => reducer.recentRead
)
