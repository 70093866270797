import { createSelector } from '@reduxjs/toolkit'

import { I_USER_BADGES_STATE } from './TYPES'

export const SLICE_NAME = 'userBadges'

const select = (state: any): I_USER_BADGES_STATE => state[SLICE_NAME]

export const getUserBadgesReducer = createSelector(select, reducer => reducer)

export const getUserBadgesOwnedSelector = createSelector(
  select,
  reducer => reducer.badgesOwned
)

export const getUserBadgeGroupCountSelector = createSelector(
  select,
  reducer => reducer.badgeGroupCount
)

export const getUserBadgeGroupsSelector = createSelector(
  select,
  reducer => reducer.badgeGroups
)
