import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  approveFriendRequestTraceActions,
  getFriendRequestsTraceActions,
  getMyFriendsTraceActions,
  removeFriendTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_FRIEND_REDUCER, INITIAL_STATE } from './TYPES'

import { _keyBy } from '~/src/Utils/lodash'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_FRIEND_REDUCER>): void => {
    builder.addCase(
      getFriendRequestsTraceActions.success,
      (state, { payload }) => {
        state.requests = payload
      }
    )

    builder.addCase(getMyFriendsTraceActions.success, (state, { payload }) => {
      state.friends = payload
    })

    builder.addCase(removeFriendTraceActions.success, (state, { payload }) => {
      state.friends = state.friends.filter(
        friend => friend.friendId !== payload.id
      )
    })

    builder.addCase(
      approveFriendRequestTraceActions.success,
      (state, { payload }) => {
        const friendsMap = _keyBy(state.friends, 'id')
        friendsMap[payload.id] = payload
        state.friends = Object.values(friendsMap)
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
