import DE_HEADER_PAGE_CONSTANTS from './de'
import EN_HEADER_PAGE_CONSTANTS from './en'
import FR_HEADER_PAGE_CONSTANTS from './fr'
import HU_HEADER_PAGE_CONSTANTS from './hu'
import { T_HEADER_PAGE_CONSTANTS } from './TYPES'

import { T_GENERIC_LANGUAGE_MAP } from '~/src/Constants/LANGUAGE'

import objectPathFactory from '~/src/Lib/objectPathFactory'

const HEADER_PAGE_CONSTANTS: T_GENERIC_LANGUAGE_MAP<T_HEADER_PAGE_CONSTANTS> = {
  hu: HU_HEADER_PAGE_CONSTANTS,
  en: EN_HEADER_PAGE_CONSTANTS,
  fr: FR_HEADER_PAGE_CONSTANTS,
  de: DE_HEADER_PAGE_CONSTANTS
}

export default HEADER_PAGE_CONSTANTS

export const HEADER_PAGE_CONSTANTS_PATH =
  objectPathFactory<T_HEADER_PAGE_CONSTANTS>()
