import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'
import { endOfDay, parse } from 'date-fns'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  getPlatformUsersTraceActions,
  getPlatformUserTraceActions,
  registerUserActions,
  updatePlatformUserTraceActions
} from '../User/Actions'
import { setPlatformUsersFilterBy, setPlatformUsersSortBy } from './Actions'
import { SLICE_NAME } from './Selectors'
import {
  I_PLATFORM_USER,
  I_PLATFORM_USER_REDUCER,
  INITIAL_STATE
} from './TYPES'

import { userLevelCalculator } from '~/src/Utils/userLevelCalculator'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      setPlatformUsersFilterBy,
      (state: I_PLATFORM_USER_REDUCER, { payload }) => {
        state.filterBy = payload
      }
    )

    builder.addCase(
      setPlatformUsersSortBy,
      (state: I_PLATFORM_USER_REDUCER, { payload }) => {
        state.sortBy = payload
      }
    )

    builder.addCase(
      getPlatformUsersTraceActions.success,
      (state: I_PLATFORM_USER_REDUCER, { payload }) => {
        state.platformUsers.ADULT = payload.ADULT.map(_sanitizeUser)
        state.platformUsers.CHILD = payload.CHILD.map(_sanitizeUser)
        state.selectedUser = INITIAL_STATE.selectedUser
      }
    )

    builder.addCase(
      getPlatformUserTraceActions.success,
      (state: I_PLATFORM_USER_REDUCER, { payload }) => {
        state.selectedUser = _sanitizeUser(payload)
      }
    )

    builder.addCase(
      updatePlatformUserTraceActions.success,
      (state: I_PLATFORM_USER_REDUCER, { payload }) => {
        state.selectedUser = _sanitizeUser(payload)
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(getMeTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer

function _sanitizeUser(user: I_PLATFORM_USER): I_PLATFORM_USER {
  const today = new Date()
  const subscriptionStatus = user.subscriptionExpireOn
    ? today <
      endOfDay(parse(user.subscriptionExpireOn || '', 'dd/MM/yyyy', new Date()))
      ? 'ACTIVE'
      : 'EXPIRED'
    : ''

  const subscriptionTenure =
    user.subscriptionTenure === 'LIFETIME'
      ? 'LIFETIME'
      : user.subscriptionTenure
        ? `${user.subscriptionTenure} months`
        : ''

  const sanitizedUser: I_PLATFORM_USER = {
    id: user.id,
    fullName: user.fullName,
    emailId: user.emailId,
    username: user.username,
    userType: user.userType,
    dob: user.dob,
    age: user.age,
    language: user.language,
    subscriptionStatus: subscriptionStatus,
    subscriptionPlan: user.subscriptionPlan || '',
    subscriptionTenure: subscriptionTenure,
    subscriptionExpireOn: user.subscriptionExpireOn || '',
    subscriptionType: user.subscriptionType,
    avatar: user.avatar || '',
    userBank: user.userBank
      ? {
          ...user.userBank,
          userLevel: userLevelCalculator(user.userBank?.pointsEarned)
        }
      : undefined,
    competitionCount:
      typeof user.competitionCount === 'number'
        ? user.competitionCount
        : undefined,
    bookCount: typeof user.bookCount === 'number' ? user.bookCount : undefined
  }

  return sanitizedUser
}
