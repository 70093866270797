import { createSelector } from '@reduxjs/toolkit'

import {
  I_GROUPED_PLATFORM_USER,
  I_PLATFORM_USER_REDUCER,
  T_PLATFORM_USER_TYPES
} from './TYPES'

import { _forEach, _sortBy } from '~/src/Utils/lodash'

export const SLICE_NAME = 'platformUsers'

const select = (state: any) => state[SLICE_NAME] as I_PLATFORM_USER_REDUCER

export const getPlatformUsersReducer = select

export const getPlatformUsersSelector = createSelector(select, reducer => {
  switch (reducer.sortBy) {
    case 'ALPHABETICALLY': {
      return _sortPlatformUsersBy(reducer.platformUsers, ['fullName'], false)
    }
    case 'ALPHABETICALLY_REVERSE': {
      return _sortPlatformUsersBy(reducer.platformUsers, ['fullName'], true)
    }
    case 'CREATED_AT': {
      return _sortPlatformUsersBy(reducer.platformUsers, ['createdAt'], false)
    }
    case 'CREATED_AT_REVERSE': {
      return _sortPlatformUsersBy(reducer.platformUsers, ['createdAt'], true)
    }
    default: {
      return _sortPlatformUsersBy(reducer.platformUsers, ['fullName'], false)
    }
  }
})

export const getFilterByPlatformUserSelector = createSelector(
  select,
  reducer => reducer.filterBy
)

export const getSortByPlatformUserSelector = createSelector(
  select,
  reducer => reducer.sortBy
)

export const getSelectedPlatformUserSelector = createSelector(
  select,
  reducer => reducer.selectedUser
)

function _sortPlatformUsersBy(
  platformUsers: I_GROUPED_PLATFORM_USER,
  key: any,
  reverse: boolean = false
) {
  const groupedUsers: I_GROUPED_PLATFORM_USER = {
    CHILD: [],
    ADULT: []
  }
  _forEach(platformUsers, (value, groupKey) => {
    const sortedGroup = _sortBy(value, key)
    groupedUsers[groupKey as T_PLATFORM_USER_TYPES] = reverse
      ? sortedGroup.reverse()
      : sortedGroup
  })

  return groupedUsers
}
