import traceActionsCreator from '../traceActionsCreator'

export const registerUserServiceName = 'User/registerUser'
export const registerUserActions = traceActionsCreator(registerUserServiceName)

export const getUsersByEmailIdServiceName = 'User/getUsersByEmailId'
export const getUsersByEmailIdActions = traceActionsCreator(
  getUsersByEmailIdServiceName
)

export const initiateForgotPasswordServiceName = 'User/initiateForgotPassword'
export const initiateForgotPasswordActions = traceActionsCreator(
  initiateForgotPasswordServiceName
)

export const confirmForgotPasswordServiceName = 'User/confirmForgotPassword'
export const confirmForgotPasswordActions = traceActionsCreator(
  confirmForgotPasswordServiceName
)

export const resetForgottenPasswordServiceName = 'User/resetForgottenPassword'
export const resetForgottenPasswordActions = traceActionsCreator(
  resetForgottenPasswordServiceName
)

export const otpRegenerateServiceName = 'User/otpRegenerate'
export const otpRegenerateActions = traceActionsCreator(
  otpRegenerateServiceName
)

export const updateProfileServiceName = 'User/updateProfile'
export const updateProfileTraceActions = traceActionsCreator(
  updateProfileServiceName
)

export const getPlatformUsersServiceName = 'User/getPlatformUsers'
export const getPlatformUsersTraceActions = traceActionsCreator(
  getPlatformUsersServiceName
)

export const getPlatformUserServiceName = 'User/getPlatformUser'
export const getPlatformUserTraceActions = traceActionsCreator(
  getPlatformUserServiceName
)

export const updatePlatformUserServiceName = 'User/updatePlatformUser'
export const updatePlatformUserTraceActions = traceActionsCreator(
  updatePlatformUserServiceName
)

export const getUserBankServiceName = 'User/getUserBank'
export const getUserBankTraceActions = traceActionsCreator(
  getUserBankServiceName
)

export const getUserBadgesServiceName = 'User/getUserBadges'
export const getUserBadgesTraceActions = traceActionsCreator(
  getUserBadgesServiceName
)

export const verifyPasswordServiceName = 'User/verifyPassword'
export const verifyPasswordTraceActions = traceActionsCreator(
  verifyPasswordServiceName
)

export const deleteAccountServiceName = 'User/deleteAccount'
export const deleteAccountTraceActions = traceActionsCreator(
  deleteAccountServiceName
)

export const getMeServiceName = 'User/getMe'
export const getMeTraceActions = traceActionsCreator(getMeServiceName)

export const reactivateAccountServiceName = 'User/reactivateAccount'
export const reactivateAccountTraceActions = traceActionsCreator(
  reactivateAccountServiceName
)

export const updateDeviceIdServiceName = 'User/updateDeviceId'
export const updateDeviceIdTraceActions = traceActionsCreator(
  updateDeviceIdServiceName
)
