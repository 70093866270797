import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { DsBox } from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import Loader from '~/src/Components/Loader'

import Header from './Header'

import { getIsLoggedInSelector } from '~/src/Redux/Auth/Selectors'
import { getUserUserTypeSelector } from '~/src/Redux/User/Selectors'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { USER_TYPE_CONSTANTS } from '~/src/Constants/USER'

interface IUnauthenticatedLayoutProps extends IWithRouterProps {
  isLoggedIn: boolean
  userType: string
}

class UnauthenticatedLayout extends PureComponent<IUnauthenticatedLayoutProps> {
  componentDidMount(): void {
    const { isLoggedIn, userType } = this.props
    if (isLoggedIn) {
      setTimeout(() => {
        if (userType === USER_TYPE_CONSTANTS.ADULT) {
          this.props.navigateTo(APP_ROUTES.DASHBOARD_ADULT.pathname)
        }

        if (userType == USER_TYPE_CONSTANTS.CHILD) {
          this.props.navigateTo(APP_ROUTES.DASHBOARD_CHILD.pathname)
        }
      }, 0)
    }
  }

  render() {
    const { isLoggedIn } = this.props

    return (
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'var(--vh)', // TODO: Solve for web and cordova
          overflow: 'auto'
          // scrollbarWidth: 'none',
          // '&::-webkit-scrollbar': {
          //   display: 'none'
          // }
        }}
      >
        {isLoggedIn ? (
          <Loader />
        ) : (
          <>
            <Header />
            <Outlet />
          </>
        )}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isLoggedIn = getIsLoggedInSelector(state)
  const userType = getUserUserTypeSelector(state)
  return { isLoggedIn, userType }
}

export default connect(mapStateToProps, null)(withRouter(UnauthenticatedLayout))
