import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'
import { endOfDay, parse } from 'date-fns'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions,
  updateDeviceIdTraceActions,
  updateProfileTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_USER_STATE, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_USER_STATE>): void => {
    builder.addCase(registerUserActions.success, (state, { payload }) => {
      const { user } = payload
      const sanitizedUser = _sanitizeUser(user)
      return sanitizedUser
    })

    builder.addCase(loginTraceActions.success, (state, { payload }) => {
      const { user } = payload
      const sanitizedUser = _sanitizeUser(user)
      return sanitizedUser
    })

    builder.addCase(updateProfileTraceActions.success, (state, { payload }) => {
      const sanitizedUser = _sanitizeUser(payload)
      return sanitizedUser
    })

    builder.addCase(
      updateDeviceIdTraceActions.success,
      (state, { payload }) => {
        const sanitizedUser = _sanitizeUser(payload)
        return sanitizedUser
      }
    )

    builder.addCase(getMeTraceActions.success, (state, { payload }) => {
      const sanitizedUser = _sanitizeUser(payload)
      return sanitizedUser
    })

    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer

function _sanitizeUser(user: I_USER_STATE): I_USER_STATE {
  const today = new Date()
  const subscriptionStatus = user.subscriptionExpireOn
    ? today <
      endOfDay(parse(user.subscriptionExpireOn || '', 'dd/MM/yyyy', new Date()))
      ? 'ACTIVE'
      : 'EXPIRED'
    : ''

  const subscriptionTenure =
    user.subscriptionTenure === 'LIFETIME'
      ? 'LIFETIME'
      : user.subscriptionTenure
        ? `${user.subscriptionTenure} months`
        : ''

  const sanitizedUser: I_USER_STATE = {
    id: user.id,
    fullName: user.fullName,
    emailId: user.emailId,
    username: user.username,
    userType: user.userType,
    dob: user.dob,
    language: user.language || INITIAL_STATE.language,
    avatar: user.avatar || INITIAL_STATE.avatar,
    subscriptionStatus: subscriptionStatus,
    subscriptionPlan: user.subscriptionPlan || '',
    subscriptionTenure: subscriptionTenure,
    subscriptionExpireOn: user.subscriptionExpireOn || '',
    subscriptionType: user.subscriptionType || INITIAL_STATE.subscriptionType,
    deviceId: user.deviceId || INITIAL_STATE.deviceId
  }

  return sanitizedUser
}
