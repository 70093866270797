import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { I_COMPETITION_FORM_STATE } from './TYPES'

export const competionUpdateInfoAction = createAction<I_COMPETITION_FORM_STATE>(
  'CompetitionForm/updateInfo'
)

export const createCompetitionServiceName = 'Competition/createCompetition'
export const createCompetitionActions = traceActionsCreator(
  createCompetitionServiceName
)
