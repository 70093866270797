const DE_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/de.png?quality=100',
      import.meta.url
    ).href,
    alt: 'DE',
    as: 'image/png'
  }
]

export default DE_FLAG_IMAGE
