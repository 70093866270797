import traceActionsCreator from '../traceActionsCreator'

export const getMyFriendsServiceName = 'Friend/getMyFriends'
export const getMyFriendsTraceActions = traceActionsCreator(
  getMyFriendsServiceName
)

export const sendFriendRequestServiceName = 'Friend/sendFriendRequest'
export const sendFriendRequestTraceActions = traceActionsCreator(
  sendFriendRequestServiceName
)

export const approveFriendRequestServiceName = 'Friend/approveFriendRequest'
export const approveFriendRequestTraceActions = traceActionsCreator(
  approveFriendRequestServiceName
)

export const rejectFriendRequestServiceName = 'Friend/rejectFriendRequest'
export const rejectFriendRequestTraceActions = traceActionsCreator(
  rejectFriendRequestServiceName
)

export const removeFriendServiceName = 'Friend/removeFriend'
export const removeFriendTraceActions = traceActionsCreator(
  removeFriendServiceName
)

export const getFriendRequestsServiceName = 'Friend/getFriendRequests'
export const getFriendRequestsTraceActions = traceActionsCreator(
  getFriendRequestsServiceName
)
