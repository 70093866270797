import { createSelector } from '@reduxjs/toolkit'

import { I_SETTINGS_STATE } from './TYPES'

export const SLICE_NAME = 'settings'

const select = (state: any) => state[SLICE_NAME] as I_SETTINGS_STATE

export const getAudioSettingsSelector = createSelector(
  select,
  reducer => reducer.audio
)
