import { T_COUPON } from '~/src/Entities/Coupon/Coupon.Types'
import { T_ORDER } from '~/src/Entities/Order/Order.Types'
import {
  I_SUBSCRIPTION,
  I_SUBSCRIPTION_TENURES
} from '~/src/Entities/Subscription/Subscription.Types'

export interface I_SUBSCRIPTION_STATE {
  subscriptions: I_SUBSCRIPTION[]
  selected?: I_SUBSCRIPTION
  selectedTenure?: I_SUBSCRIPTION_TENURES
  coupon?: T_COUPON
  order?: T_ORDER
  orderHistory: T_ORDER[]
}

export const INITIAL_STATE: I_SUBSCRIPTION_STATE = {
  subscriptions: [],
  orderHistory: []
}
