import { T_COVER_IMAGE_IDS } from '~/src/Constants/COMPETITION_COVER'

export interface I_COMPETITION_MEMBERS {
  id: string
  fullName: string
  emailId: string
  username: string
  dob: string
  age: number
  status: string
}

export interface I_COMPETITION_BOOKS {
  id: string
  bookName: string
  coverImage: string
  minAge: number
}

export interface I_COMPETITION_FORM_STATE {
  competitionName: string
  competitionDescription: string
  remarks: string
  minAge: number
  maxAge: number
  anonymous: boolean

  startDate: string
  endDate: string

  members: any[]
  books: any[]
  groups: any[]

  coverId: T_COVER_IMAGE_IDS | ''
  coverImage?: string
  coverImageMimeType?: string
}

export const INITIAL_STATE: I_COMPETITION_FORM_STATE = {
  competitionName: '',
  competitionDescription: '',
  remarks: '',
  minAge: 0,
  maxAge: 18,
  anonymous: false,
  startDate: '',
  endDate: '',
  members: [],
  books: [],
  groups: [],
  coverId: '',
  coverImage: '',
  coverImageMimeType: ''
}
