import {
  I_USER_TYPE_VALUES,
  T_SUBSCRIPTION_STATUS,
  T_USER_SUB_TYPE_VALUES
} from '~/src/Constants/USER'

export interface I_USER_STATE {
  id: string
  fullName: string
  emailId: string
  username: string
  userType: I_USER_TYPE_VALUES
  dob: string
  language: string
  avatar: string
  subscriptionType?: T_USER_SUB_TYPE_VALUES
  subscriptionExpireOn?: string
  subscriptionPlan?: string
  subscriptionTenure?: string
  subscriptionStatus?: T_SUBSCRIPTION_STATUS | ''
  deviceId?: string
}

export const INITIAL_STATE: I_USER_STATE = {
  id: '',
  fullName: '',
  emailId: '',
  username: '',
  userType: 'CHILD',
  dob: '',
  language: 'hu',
  avatar: '',
  subscriptionType: 'FREE',
  subscriptionExpireOn: '',
  subscriptionPlan: '',
  subscriptionStatus: '',
  subscriptionTenure: '',
  deviceId: ''
}
