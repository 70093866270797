import './index.scss'

import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { Outlet } from 'react-router-dom'
import { DsBox } from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import DocumentEventListener from '~/src/Components/DocumentEventListener'
import FcmNotification from '~/src/Components/Fcm/FcmNotification'

import { getAudioSettingsSelector } from '~/src/Redux/Settings/Selectors'
import { getUserReducer } from '~/src/Redux/User/Selectors'
import getUserBankService from '~/src/Redux/User/Services/getUserBank.Service'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import BACKGROUND_SOUND from '~/src/Constants/ASSETS/AUDIOS/BACKGROUND_SOUND'
import CLICK_SOUND from '~/src/Constants/ASSETS/AUDIOS/CLICK_SOUND'

interface IChildAuthenticatedLayoutProps
  extends PropsFromRedux,
    IWithRouterProps {}

class ChildAuthenticatedLayout extends PureComponent<IChildAuthenticatedLayoutProps> {
  async componentDidMount() {
    this.registerSound()
    const { actions } = this.props
    await actions.getUserBank()
    this.handleExpiredSubscription()
  }

  componentDidUpdate(): void {
    this.handleExpiredSubscription()
  }

  componentWillUnmount(): void {
    this.unRegisterSound()
  }

  handleExpiredSubscription = () => {
    const { authUser, location } = this.props

    if (
      authUser.subscriptionStatus === 'EXPIRED' &&
      location.pathname !== APP_ROUTES.PROFILE_CHILD.pathname
    ) {
      this.props.navigateTo(APP_ROUTES.PROFILE_CHILD.pathname)
    }
  }

  registerSound = () => {
    const { audioSettings } = this.props
    const { click, background } = audioSettings

    CLICK_SOUND.volume = click.muted ? 0 : click.volume
    BACKGROUND_SOUND.volume = background.muted ? 0 : background.volume

    document.onclick = () => CLICK_SOUND.play()
    BACKGROUND_SOUND.play()
  }

  unRegisterSound = () => {
    document.onclick = null
    BACKGROUND_SOUND.pause()
    BACKGROUND_SOUND.currentTime = 0
  }

  render() {
    return (
      <DsBox
        id='main-child-layout'
        className='child-layout'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'var(--vh)', // TODO: Solve for web and cordova
          background: 'var(--ds-colour-surfaceBackground)',
          overflow: 'auto'
          // scrollbarWidth: 'none',
          // '&::-webkit-scrollbar': {
          //   display: 'none'
          // }
        }}
      >
        <DocumentEventListener />
        <FcmNotification />
        <Outlet />
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const authUser = getUserReducer(state)
  const audioSettings = getAudioSettingsSelector(state)
  return { authUser, audioSettings }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getUserBank: () => dispatch(getUserBankService())
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withRouter(ChildAuthenticatedLayout))
