import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'

import { changeLanguageServiceNameActions } from '../Redux/Language/Actions'
import { getSelectedLanguageSelector } from '../Redux/Language/Selectors'

import { T_GENERIC_LANGUAGE_MAP, T_LANGUAGE_CODES } from '../Constants/LANGUAGE'

/**
 * Basic Interface to extend in components wrapping the below HOC.
 *
 * @export
 * @interface IWithPageConstantsProps
 */
export interface IWithPageConstantsProps<T> {
  selectedLanguage: T_LANGUAGE_CODES
  PAGE_CONSTANTS: T
  onChangeLanguage: (selectedLanguage: T_LANGUAGE_CODES) => void
}

/**
 * HOC to provide current mode & toggle mode feature
 *
 * @export
 * @param {React.ComponentType<any>} Child
 * @return {*}  {React.ComponentType<any>}
 */

export default function withPageConstants<T>(
  PAGE_CONSTANTS_MAP: Partial<T_GENERIC_LANGUAGE_MAP<T>>,
  Child: React.ComponentType<any>
): React.ComponentType<any> {
  interface IPageConstantSelectorProps {
    selectedLanguage: T_LANGUAGE_CODES
    actions: {
      changeLanguageAction: (selectedLanguage: T_LANGUAGE_CODES) => void
    }
  }

  class PageConstantSelector extends Component<IPageConstantSelectorProps> {
    render() {
      const { selectedLanguage, actions, ...restProps } = this.props
      const { changeLanguageAction } = actions
      const PAGE_CONSTANTS = PAGE_CONSTANTS_MAP[selectedLanguage]
      return (
        <Child
          selectedLanguage={selectedLanguage}
          onChangeLanguage={changeLanguageAction}
          PAGE_CONSTANTS={PAGE_CONSTANTS}
          {...restProps}
        />
      )
    }
  }

  const mapStateToProps = (state: any) => {
    const selectedLanguage = getSelectedLanguageSelector(state)

    return {
      selectedLanguage
    }
  }

  const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
    actions: {
      changeLanguageAction: (selectedLanguage: T_LANGUAGE_CODES) =>
        dispatch(changeLanguageServiceNameActions.success(selectedLanguage))
    }
  })

  return connect(mapStateToProps, mapDispatchToProps)(PageConstantSelector)
}
