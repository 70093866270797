import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'forgotCredentials'

// const select = (state: any) => state[SLICE_NAME]
const selectUsersByEmailId = (state: any) => state[SLICE_NAME].usersByEmailId
const selectForgotPasswordRefId = (state: any) =>
  state[SLICE_NAME].forgotPasswordRefId
const selectForgotPasswordToken = (state: any) =>
  state[SLICE_NAME].forgotPasswordToken
const selectForgotPasswordShowOtp = (state: any) =>
  state[SLICE_NAME].forgotPasswordShowOtp
const selectForgotPasswordShowOtpLimitPopup = (state: any) =>
  state[SLICE_NAME].forgotPasswordShowOtpLimitPopup

export const getUsersByEmailIdSelector = createSelector(
  selectUsersByEmailId,
  usersByEmailId => usersByEmailId || []
)

export const getForgotPasswordRefIdSelector = createSelector(
  selectForgotPasswordRefId,
  forgotPasswordRefId => forgotPasswordRefId
)

export const getForgotPasswordTokenSelector = createSelector(
  selectForgotPasswordToken,
  forgotPasswordToken => forgotPasswordToken
)

export const getForgotPasswordShowOtpSelector = createSelector(
  selectForgotPasswordShowOtp,
  forgotPasswordShowOtp => forgotPasswordShowOtp
)
export const getForgotPasswordShowOtpLimitPopupSelector = createSelector(
  selectForgotPasswordShowOtpLimitPopup,
  forgotPasswordShowOtpLimitPopup => forgotPasswordShowOtpLimitPopup
)
