import { createAction } from '@reduxjs/toolkit'

import { I_BOOK_FORM_STATE, T_BOOK_FORM_BOOK_INFO_PROPS } from './TYPES'

export const bookFormUpdateBookInfoAction = createAction<
  Pick<I_BOOK_FORM_STATE, T_BOOK_FORM_BOOK_INFO_PROPS>
>('BookForm/updateBookInfo')

export const bookFormUpdateQuestionsAction = createAction<
  I_BOOK_FORM_STATE['questions']
>('BookForm/updateQuestions')

export const resetBookFormReducerAction = createAction('BookForm/resetReducer')
