import traceActionsCreator from '../traceActionsCreator'

export const listCouponsServiceName = 'Coupon/listCoupons'
export const listCouponsTraceActions = traceActionsCreator(
  listCouponsServiceName
)

export const createCouponServiceName = 'Coupon/createCoupon'
export const createCouponTraceActions = traceActionsCreator(
  createCouponServiceName
)

export const findCouponByIdServiceName = 'Coupon/findCouponById'
export const findCouponByIdTraceActions = traceActionsCreator(
  findCouponByIdServiceName
)

export const updateCouponByIdServiceName = 'Coupon/updateCouponById'
export const updateCouponByIdTraceActions = traceActionsCreator(
  updateCouponByIdServiceName
)

export const validateCouponServiceName = 'Coupon/validateCoupon'
export const validateCouponTraceActions = traceActionsCreator(
  validateCouponServiceName
)

export const removeCouponByIdServiceName = 'Coupon/removeCouponById'
export const removeCouponByIdTraceActions = traceActionsCreator(
  removeCouponByIdServiceName
)

export const couponExistsServiceName = 'Coupon/couponExists'
export const couponExistsTraceActions = traceActionsCreator(
  couponExistsServiceName
)
