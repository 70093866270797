import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { competitionCreateActions } from '../Competition/Actions'
import { changeLanguageServiceNameActions } from '../Language/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions,
  updateProfileTraceActions
} from '../User/Actions'
import {
  findBookByIdTraceActions,
  getMyBooksTraceActions,
  getRecentlyReadTraceActions,
  listBooksTraceActions,
  resetBookReducer,
  setBookSearchPropsAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_BOOK_REDUCER, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_BOOK_REDUCER>): void => {
    builder.addCase(getMyBooksTraceActions.success, (state, { payload }) => {
      state.books = payload
      state.book = INITIAL_STATE.book
    })

    builder.addCase(listBooksTraceActions.success, (state, { payload }) => {
      state.books = payload
      state.book = INITIAL_STATE.book
    })

    builder.addCase(findBookByIdTraceActions.success, (state, { payload }) => {
      state.book = payload
    })

    builder.addCase(setBookSearchPropsAction, (state, { payload }) => {
      state.searchProps = payload
    })

    builder.addCase(
      getRecentlyReadTraceActions.success,
      (state, { payload }) => {
        state.recentRead = payload
      }
    )

    builder.addCase(
      changeLanguageServiceNameActions.success,
      (state, { payload }) => {
        state.searchProps.language = payload
      }
    )

    builder.addCase(updateProfileTraceActions.success, (state, { payload }) => {
      state.searchProps.language = payload.language
    })

    builder.addCase(resetBookReducer, (state, { payload }) => ({
      ...INITIAL_STATE,
      searchProps: { ...INITIAL_STATE.searchProps, ...payload }
    }))

    builder.addCase(competitionCreateActions.success, state => ({
      ...INITIAL_STATE,
      searchProps: { ...state.searchProps, minAge: '', maxAge: '' }
    }))

    builder.addCase(loginTraceActions.success, (state, { payload }) => ({
      ...INITIAL_STATE,
      searchProps: { ...state.searchProps, language: payload.user.language }
    }))
    builder.addCase(registerUserActions.success, (state, { payload }) => ({
      ...INITIAL_STATE,
      searchProps: { ...state.searchProps, language: payload.user.language }
    }))
    builder.addCase(getMeTraceActions.success, (state, { payload }) => ({
      ...INITIAL_STATE,
      searchProps: { ...state.searchProps, language: payload.language }
    }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
