export type T_GROUP_STATUS = 'ACTIVE' | 'ARCHIVED'
export type T_GROUP_FILTERS = 'ALL' | T_GROUP_STATUS
export type T_GROUP_SORTS =
  | 'ALPHABETICALLY'
  | 'ALPHABETICALLY_REVERSE'
  | 'CREATED_AT'
  | 'CREATED_AT_REVERSE'
  | 'MEMBERS_COUNT'
  | 'MEMBERS_COUNT_REVERSE'

export interface I_GROUP_MEMBERS {
  id: string
  fullName: string
  emailId: string
  username: string
  dob: string
  age: number
}

export interface I_GROUP {
  id?: string
  groupName: string
  groupDescription: string
  minAge?: number

  memberIds?: string[]
  members: I_GROUP_MEMBERS[]
  memberCount?: number

  createdAt: string
  createdBy: string
  status: T_GROUP_STATUS
}

export type I_GROUPED_GROUP = { [key in T_GROUP_STATUS]: I_GROUP[] }

export interface I_GROUP_REDUCER {
  groups: I_GROUPED_GROUP
  filterBy: T_GROUP_FILTERS
  sortBy: T_GROUP_SORTS
  group: I_GROUP
}

export const INITIAL_STATE: I_GROUP_REDUCER = {
  groups: {
    ACTIVE: [],
    ARCHIVED: []
  },
  filterBy: 'ALL',
  sortBy: 'ALPHABETICALLY',
  group: {
    id: '',
    groupName: '',
    groupDescription: '',
    minAge: 0,
    members: [],
    memberCount: 0,
    createdAt: '',
    createdBy: '',
    status: 'ACTIVE'
  }
}
