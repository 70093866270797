import { createSelector } from '@reduxjs/toolkit'

import { I_FRIEND_REDUCER } from './TYPES'

export const SLICE_NAME = 'friend'

const select = (state: any) => state[SLICE_NAME] as I_FRIEND_REDUCER

export const getFriendReducer = select

export const getMyFriendsSelector = createSelector(
  select,
  reducer => reducer.friends
)

export const getFriendRequestsSelector = createSelector(
  select,
  reducer => reducer.requests
)

export const getPendingApprovalsSelector = createSelector(
  select,
  reducer => reducer.pendingApprovals
)
