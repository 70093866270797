import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  findBookByIdTraceActions,
  getMyBooksTraceActions,
  listBooksTraceActions
} from '../Book/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  bookFormUpdateBookInfoAction,
  bookFormUpdateQuestionsAction,
  resetBookFormReducerAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_BOOK_FORM_STATE, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      bookFormUpdateBookInfoAction,
      (state: I_BOOK_FORM_STATE, { payload }) => {
        state.bookName = payload.bookName
        state.genre = payload.genre
        state.isSeries = payload.isSeries
        state.seriesNo = payload.seriesNo
        state.seriesName = payload.seriesName
        state.language = payload.language
        state.authors = payload.authors
        state.publisher = payload.publisher
        state.noOfPages = payload.noOfPages
        state.readTimeInMins = payload.readTimeInMins
        state.coverImage = payload.coverImage
        state.coverImageMimeType = payload.coverImageMimeType
        state.minAge = payload.minAge
        state.tags = payload.tags
      }
    )

    builder.addCase(
      bookFormUpdateQuestionsAction,
      (state: I_BOOK_FORM_STATE, { payload }) => {
        state.questions = payload
      }
    )

    builder.addCase(getMyBooksTraceActions.success, () => ({
      ...INITIAL_STATE
    }))

    builder.addCase(listBooksTraceActions.success, () => ({ ...INITIAL_STATE }))

    builder.addCase(
      findBookByIdTraceActions.success,
      (state: I_BOOK_FORM_STATE, { payload }) => {
        state.id = payload.id
        state.bookName = payload.bookName
        state.genre = payload.genre
        state.isSeries = payload.isSeries
        state.seriesNo = payload.seriesNo
        state.seriesName = payload.seriesName
        state.language = payload.language
        state.authors = payload.authors
        state.publisher = payload.publisher
        state.noOfPages = payload.noOfPages
        state.readTimeInMins = payload.readTimeInMins
        state.coverImage = payload.coverImage
        // state.coverImageMimeType = payload.coverImageMimeType
        state.minAge = payload.minAge
        state.tags = payload.tags
        state.questions = payload.questions
      }
    )

    builder.addCase(resetBookFormReducerAction, () => ({ ...INITIAL_STATE }))

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
