interface I_AUDIO_SETTINGS_INTERNAL {
  volume: number
  muted: boolean
}

export interface I_AUDIO_SETTINGS {
  click: I_AUDIO_SETTINGS_INTERNAL
  background: I_AUDIO_SETTINGS_INTERNAL
}
export interface I_SETTINGS_STATE {
  audio: I_AUDIO_SETTINGS
}

const AUDIO_SETTINGS: I_AUDIO_SETTINGS = {
  click: {
    volume: 1,
    muted: false
  },
  background: {
    volume: 0.1,
    muted: false
  }
}

export const INITIAL_STATE: I_SETTINGS_STATE = {
  audio: AUDIO_SETTINGS
}
