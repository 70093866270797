import { WebHttpRequestOptions } from '@am92/web-http'

import { getMeServiceName, getMeTraceActions } from '../Actions'

import serviceActionCreatorWithTokenRotation from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import { asHttp } from '~/src/Configurations/WebHttp'

async function getMe() {
  const options: WebHttpRequestOptions = {
    url: '/users/me',
    method: 'GET'
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getMeService = serviceActionCreatorWithTokenRotation(
  getMeTraceActions,
  getMe
)

export default getMeService
export { getMeServiceName }
