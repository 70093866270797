import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { I_APPEND_RESULT_ACTION } from './TYPES'

// export const sampleAction = createAction('BookRead/sample')

export const bookMarkReadServiceName = 'BookRead/bookMarkRead'
export const bookMarkReadTraceActions = traceActionsCreator(
  bookMarkReadServiceName
)

export const resetBookReadAction = createAction<void, string>(
  'BookRead/resetBookRead'
)

export const appendBookReadResultAction = createAction<
  I_APPEND_RESULT_ACTION,
  string
>('BookRead/appendResult')

export const increaseBookReadActiveIndexAction = createAction(
  'BookRead/increaseActiveIndex'
)

export const decreaseBookReadActiveIndexAction = createAction(
  'BookRead/decreaseActiveIndex'
)

export const getBookForReadingServiceName = 'BookRead/getBookForReading'
export const getBookForReadingTraceActions = traceActionsCreator(
  getBookForReadingServiceName
)

export const bookFeedbackServiceName = 'BookRead/bookFeedback'
export const bookFeedbackTraceActions = traceActionsCreator(
  bookFeedbackServiceName
)
