import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import {
  deleteAccountTraceActions,
  getMeTraceActions,
  registerUserActions,
  updateProfileTraceActions
} from '../User/Actions'
import { changeLanguageServiceNameActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_LANGUAGE_STATE, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      changeLanguageServiceNameActions.success,
      (state: I_LANGUAGE_STATE, { payload }) => {
        state.selectedLanguage = payload || INITIAL_STATE.selectedLanguage
      }
    )
    builder.addCase(
      loginTraceActions.success,
      (state: I_LANGUAGE_STATE, { payload }) => {
        state.selectedLanguage =
          payload?.user?.language || INITIAL_STATE.selectedLanguage
      }
    )
    builder.addCase(
      registerUserActions.success,
      (state: I_LANGUAGE_STATE, { payload }) => {
        state.selectedLanguage =
          payload?.user?.language || INITIAL_STATE.selectedLanguage
      }
    )
    builder.addCase(
      updateProfileTraceActions.success,
      (state: I_LANGUAGE_STATE, { payload }) => {
        state.selectedLanguage =
          payload?.language || INITIAL_STATE.selectedLanguage
      }
    )
    builder.addCase(
      getMeTraceActions.success,
      (state: I_LANGUAGE_STATE, { payload }) => {
        state.selectedLanguage =
          payload?.language || INITIAL_STATE.selectedLanguage
      }
    )
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
