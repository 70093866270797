import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'competitionForm'

const select = (state: any) => state[SLICE_NAME]

export const getCompetitionFormReducer = createSelector(
  select,
  reducer => reducer || {}
)
