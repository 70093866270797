import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useColorScheme } from '@am92/react-design-system'

import { getThemeReducer } from '../Redux/Theme/Selectors'

interface IThemeSetterProps {}

const ThemeSetter: FunctionComponent<IThemeSetterProps> = () => {
  const theme = useSelector(getThemeReducer)
  const { scheme: themeScheme } = theme
  const { colorScheme, setColorScheme } = useColorScheme()

  useEffect(() => {
    if (colorScheme !== themeScheme) {
      setColorScheme(themeScheme)
    }
  }, [themeScheme])
  return <></>
}

export default ThemeSetter
