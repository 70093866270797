import React, { Component } from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import LanguageSelector from '~/src/Components/LanguageSelector'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import LOGO_IMAGE from '~/src/Constants/ASSETS/MISC/LOGO_IMAGE'

interface IHeaderProps extends IWithRouterProps {}

class Header extends Component<IHeaderProps> {
  handleGoToLogin = () => {
    this.props.navigateTo(APP_ROUTES.LOGIN.pathname)
  }

  render() {
    return (
      <DsBox
        sx={theme => ({
          px: {
            xs: 'var(--ds-spacing-bitterCold)',
            lg: 'var(--ds-spacing-pleasant)'
          },
          py: 'var(--ds-spacing-bitterCold)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar
        })}
      >
        <DsStack
          direction='row'
          spacing='var(--ds-spacing-glacial)'
          onClick={this.handleGoToLogin}
          sx={{ alignItems: 'center', cursor: 'pointer' }}
        >
          <DsImage
            aspectRatio={1 / 1}
            srcSet={LOGO_IMAGE}
            WrapperProps={{ sx: { height: '48px', width: '48px' } }}
            style={{ display: 'block' }}
          />

          <DsTypography
            component='div'
            variant='headingBoldLarge'
            sx={{
              fontWeight: 'var(--ds-typo-fontWeightLight)',
              display: { xs: 'none', lg: 'block' }
            }}
          >
            GriffyReads
          </DsTypography>
        </DsStack>
        <LanguageSelector />
      </DsBox>
    )
  }
}

export default withRouter(Header)
