import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { deleteAccountTraceActions, registerUserActions } from '../User/Actions'
import {
  addBookToLibraryTraceActions,
  getMyLibraryTraceActions,
  getSharedLibraryTraceActions,
  removeBookFromLibraryTraceActions,
  updateMyLibraryTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_LIBRARY_STATE, INITIAL_STATE } from './Types'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_LIBRARY_STATE>): void => {
    builder.addCase(getMyLibraryTraceActions.success, (state, { payload }) => {
      state.myLibrary = { ...payload }
    })

    builder.addCase(
      updateMyLibraryTraceActions.success,
      (state, { payload }) => {
        state.myLibrary = { ...payload }
      }
    )

    builder.addCase(
      addBookToLibraryTraceActions.success,
      (state, { payload }) => {
        state.myLibrary = { ...payload }
      }
    )

    builder.addCase(
      removeBookFromLibraryTraceActions.success,
      (state, { payload }) => {
        state.myLibrary = { ...payload }
      }
    )

    builder.addCase(
      getSharedLibraryTraceActions.success,
      (state, { payload }) => {
        state.sharedLibrary = { ...payload }
      }
    )

    builder.addCase(loginTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(registerUserActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.success, () => ({ ...INITIAL_STATE }))
    builder.addCase(logoutTraceActions.error, () => ({ ...INITIAL_STATE }))
    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
    builder.addCase(deleteAccountTraceActions.success, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
