import { DsPalette, SupportedColorScheme } from '@am92/react-design-system'

export type T_THEME_REDUCER = {
  scheme: SupportedColorScheme
  palette: DsPalette
  fontFamily: string
}

export const DEFAULT_THEME: T_THEME_REDUCER = {
  scheme: 'light',
  palette: {
    primary: '#EB6B42',
    secondary100: '#0A9474',
    secondary80: '#0A9474',
    secondary60: '#0A9474',
    secondary40: '#0A9474'
  },
  fontFamily: 'Noto'
}

export const CHILD_THEME: T_THEME_REDUCER = {
  scheme: 'dark',
  palette: {
    primary: '#360F7C',
    primaryBlack: '#050A34',
    // 'linear-gradient(0deg, #1F0057, #1F0057), linear-gradient(180deg, rgba(89, 31, 194, 0.5) 0%, rgba(26, 15, 53, 0.5) 100%)',
    // primaryBlackLight: '#1F0057',
    // primaryBlackLight:
    //   'radial-gradient(50% 50% at 50% 50%, #0C222F 0%, #050A34 100%)',
    secondary100: '#360F7C',
    secondary80: '#360F7C',
    secondary60: '#360F7C',
    secondary40: '#360F7C',
    secondary20: '#360F7C'
    // secondary100: '#490DB4',
    // secondary80: '#490DB4',
    // secondary60: '#490DB4',
    // secondary40: '#490DB4',
    // secondary20: '#490DB4'
  },
  fontFamily: 'Montserrat'
}

export const INITIAL_STATE: T_THEME_REDUCER = DEFAULT_THEME
