import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  setAudioBackgroundMutedAction,
  setAudioBackgroundVolumeAction,
  setAudioClickMutedAction,
  setAudioClickVolumeAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { I_SETTINGS_STATE, INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<I_SETTINGS_STATE>): void => {
    builder.addCase(setAudioClickVolumeAction, (state, { payload }) => {
      state.audio.click.volume = payload
    })

    builder.addCase(setAudioClickMutedAction, (state, { payload }) => {
      state.audio.click.muted = payload
    })

    builder.addCase(setAudioBackgroundVolumeAction, (state, { payload }) => {
      state.audio.background.volume = payload
    })

    builder.addCase(setAudioBackgroundMutedAction, (state, { payload }) => {
      state.audio.background.muted = payload
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
