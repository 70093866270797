import {
  CONSISTENT_USER_LEVEL_UP_POINTS,
  INCONSISTENT_USER_LEVEL_LIMIT,
  INCONSISTENT_USER_LEVEL_MAX_POINTS,
  INCONSISTENT_USER_LEVEL_MAX_POINTS_ARRAY,
  INCONSISTENT_USER_LEVEL_MAX_POINTS_MAP,
  MASCOT_LEVEL_MASCOT_TYPE_MAP,
  T_USER_LEVEL,
  USER_LEVEL_MASCOT_LEVEL_MAP
} from '../Constants/LEVELS'

export function userLevelCalculator(pointsEarned: number = 0): T_USER_LEVEL {
  if (pointsEarned >= INCONSISTENT_USER_LEVEL_MAX_POINTS) {
    return _getConsistentUserLevel(pointsEarned)
  } else {
    return _getInconsistentUserLevel(pointsEarned)
  }
}

function _getConsistentUserLevel(pointsEarned: number): T_USER_LEVEL {
  const consistentLevelPoints =
    pointsEarned - INCONSISTENT_USER_LEVEL_MAX_POINTS
  const consistentLevel = Math.trunc(
    consistentLevelPoints / CONSISTENT_USER_LEVEL_UP_POINTS
  )

  const currentLevel = consistentLevel + INCONSISTENT_USER_LEVEL_LIMIT
  const nextLevel = currentLevel + 1

  const currentLevelPoints =
    consistentLevelPoints % CONSISTENT_USER_LEVEL_UP_POINTS
  const pointsToLevelUp = CONSISTENT_USER_LEVEL_UP_POINTS - currentLevelPoints

  const mascotLevel = USER_LEVEL_MASCOT_LEVEL_MAP[currentLevel] || 6
  const mascotType = MASCOT_LEVEL_MASCOT_TYPE_MAP[mascotLevel]

  return {
    currentLevel,
    nextLevel,
    mascotLevel,
    mascotType,
    exp: {
      max: CONSISTENT_USER_LEVEL_UP_POINTS,
      current: currentLevelPoints,
      levelUpIn: pointsToLevelUp,
      value: (currentLevelPoints * 100) / CONSISTENT_USER_LEVEL_UP_POINTS
    }
  }
}

function _getInconsistentUserLevel(pointsEarned: number): T_USER_LEVEL {
  let currentLevel = 1
  let nextLevel = 2
  let currentLevelPoints = pointsEarned
  let currentLevelMaxPoints = INCONSISTENT_USER_LEVEL_MAX_POINTS_MAP[1]

  for (const maxPoints of INCONSISTENT_USER_LEVEL_MAX_POINTS_ARRAY) {
    if (!pointsEarned || maxPoints > pointsEarned) {
      break
    }

    const index = INCONSISTENT_USER_LEVEL_MAX_POINTS_ARRAY.indexOf(maxPoints)

    currentLevel = index + 1
    nextLevel = currentLevel + 1

    const prevLevelMaxPoints =
      INCONSISTENT_USER_LEVEL_MAX_POINTS_MAP[index] || 0
    currentLevelPoints = pointsEarned - prevLevelMaxPoints

    currentLevelMaxPoints =
      (INCONSISTENT_USER_LEVEL_MAX_POINTS_MAP[currentLevel] || 0) -
      prevLevelMaxPoints
  }

  const mascotLevel = USER_LEVEL_MASCOT_LEVEL_MAP[currentLevel] || 6
  const mascotType = MASCOT_LEVEL_MASCOT_TYPE_MAP[mascotLevel]

  return {
    currentLevel,
    nextLevel,
    mascotLevel,
    mascotType,
    exp: {
      max: currentLevelMaxPoints,
      current: currentLevelPoints,
      levelUpIn: currentLevelMaxPoints - currentLevelPoints,
      value: (currentLevelPoints * 100) / currentLevelMaxPoints
    }
  }
}
