export interface I_BOOK_ANANLYTICS {
  bookId: string
  correctAnswers: number
  incorrectAnswers: number
  pointsEarned: number
}
export interface I_COMPETITION_ANANLYTICS {
  competitionId: string
  earnablePoints: number
  pointsEarned: number
  answers: number
  answersGiven: number
  correctAnswers: number
  incorrectAnswers: number
  booksCount: number
  booksRead: number
}

export interface I_ANALYTICS_STATE {
  book: I_BOOK_ANANLYTICS
  competition: I_COMPETITION_ANANLYTICS
}

export const INITIAL_STATE: I_ANALYTICS_STATE = {
  book: {
    bookId: '',
    correctAnswers: 0,
    incorrectAnswers: 0,
    pointsEarned: 0
  },
  competition: {
    competitionId: '',
    earnablePoints: 0,
    pointsEarned: 0,
    answers: 0,
    answersGiven: 0,
    correctAnswers: 0,
    incorrectAnswers: 0,
    booksCount: 0,
    booksRead: 0
  }
}
