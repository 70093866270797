import { createSelector } from '@reduxjs/toolkit'

import { I_GROUPED_GROUP, T_GROUP_STATUS } from './TYPES'

import { _forEach, _sortBy } from '~/src/Utils/lodash'

export const SLICE_NAME = 'group'

const select = (state: any) => state[SLICE_NAME]

export const getMyGroupListSelector = createSelector(select, reducer => {
  switch (reducer.sortBy) {
    case 'ALPHABETICALLY': {
      return _sortGroupsBy(reducer.groups, ['groupName'], false)
    }
    case 'ALPHABETICALLY_REVERSE': {
      return _sortGroupsBy(reducer.groups, ['groupName'], true)
    }
    case 'CREATED_AT': {
      return _sortGroupsBy(reducer.groups, ['createdAt'], false)
    }
    case 'CREATED_AT_REVERSE': {
      return _sortGroupsBy(reducer.groups, ['createdAt'], true)
    }
    case 'MEMBERS_COUNT': {
      return _sortGroupsBy(reducer.groups, ['memberIds.length'], true)
    }
    case 'MEMBERS_COUNT_REVERSE': {
      return _sortGroupsBy(reducer.groups, ['memberIds.length'], false)
    }
    default: {
      return _sortGroupsBy(reducer.groups, ['groupName'], false)
    }
  }
})

export const getFilterByGroupSelector = createSelector(
  select,
  reducer => reducer.filterBy
)

export const getSortByGroupSelector = createSelector(
  select,
  reducer => reducer.sortBy
)

export const getGroupSelector = createSelector(select, reducer => reducer.group)

function _sortGroupsBy(
  groups: I_GROUPED_GROUP,
  key: any,
  reverse: boolean = false
) {
  const groupedGroups: I_GROUPED_GROUP = {
    ACTIVE: [],
    ARCHIVED: []
  }
  _forEach(groups, (value, groupKey) => {
    const sortedGroup = _sortBy(value, key)
    groupedGroups[groupKey as T_GROUP_STATUS] = reverse
      ? sortedGroup.reverse()
      : sortedGroup
  })

  return groupedGroups
}
