import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { I_BOOK_SEARCH_PROPS } from './TYPES'

import { T_LANGUAGE_CODES } from '~/src/Constants/LANGUAGE'

export const resetBookReducer = createAction<
  { language: T_LANGUAGE_CODES },
  string
>('Book/resetReducer')

export const findBookByIdServiceName = 'Book/findBookById'
export const findBookByIdTraceActions = traceActionsCreator(
  findBookByIdServiceName
)

export const getMyBooksServiceName = 'Book/getMyBooks'
export const getMyBooksTraceActions = traceActionsCreator(getMyBooksServiceName)

export const listBooksServiceName = 'Book/listBooks'
export const listBooksTraceActions = traceActionsCreator(listBooksServiceName)

export const createBookServiceName = 'Book/createBook'
export const createBookActions = traceActionsCreator(createBookServiceName)

export const uploadBookServiceName = 'Book/uploadBook'
export const uploadBookActions = traceActionsCreator(uploadBookServiceName)

export const updateBookServiceName = 'Book/updateBook'
export const updateBookTraceActions = traceActionsCreator(updateBookServiceName)

export const approveBookServiceName = 'Book/approveBook'
export const approveBookTraceActions = traceActionsCreator(
  approveBookServiceName
)

export const rejectBookServiceName = 'Book/rejectBook'
export const rejectBookTraceActions = traceActionsCreator(rejectBookServiceName)

export const generateBookFileUploadUrlsServiceName =
  'Book/generateFileUploadUrls'
export const generateBookFileUploadUrlsTraceActions = traceActionsCreator(
  generateBookFileUploadUrlsServiceName
)

export const bookExistsServiceName = 'Book/bookExists'
export const bookExistsTraceActions = traceActionsCreator(bookExistsServiceName)

export const setBookSearchPropsAction = createAction<
  I_BOOK_SEARCH_PROPS,
  string
>('Book/setSearchProps')

export const getRecentlyReadServiceName = 'Book/getRecentlyRead'
export const getRecentlyReadTraceActions = traceActionsCreator(
  getRecentlyReadServiceName
)
